import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { pressEnter as pressEnterApp } from "../../../../sagas/commands/insertion/pressEnter/app";
import { pressEnter as pressEnterTalkType } from "../../../../sagas/commands/insertion/pressEnter/talktype";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const pressEnter: CommandConfig = createCommandDefinition({
  id: "pressEnter",
  matchers: messages.matchers,
  type: "action",
  deadline: toSeconds(0),
  messages,
  performCommand: { talktype: pressEnterTalkType, app: pressEnterApp },
  input: messages.listing,
  track: { name: "Key Pressed", data: { key: "Enter" } },
});
