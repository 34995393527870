import type { SagaIterator } from "redux-saga";

import { call, put, take, takeEvery } from "redux-saga/effects";
import { match } from "ts-pattern";

import { isKeyboardClick } from "@carescribe/utilities/src/isKeyboardClick";
import { isMouseClick } from "@carescribe/utilities/src/isMouseClick";
import { preventDefault } from "@carescribe/utilities/src/sagas";

import {
  finalisedDictation,
  requestFinaliseDictation,
} from "@talktype/actions";

import { handleClearButton } from "./handleClearButton";
import { handleCopyButton } from "./handleCopyButton";
import { handleHistoryButtons } from "./handleHistoryButtons";
import { handleStyleFormattingButtons } from "./handleStyleFormattingButtons";
import { editorFormattingBarInteracted } from "../actions";

/**
 * Handle Formatting Buttons
 *
 * Takes interactions with the formatting bar (clicks, keypresses) and requests
 * the appropriate response be performed.
 */
export const handleFormattingButtons = function* (): SagaIterator<void> {
  yield takeEvery(editorFormattingBarInteracted, function* ({ payload }) {
    if (!isKeyboardClick(payload.event) && !isMouseClick(payload.event)) {
      return;
    }

    /**
     * Prevent default behaviour to avoid issues.
     *
     * - Keyboard click:
     *   When a button is pressed and becomes disabled, pressing another button
     *   may lead to a sibling button receiving the active state (weird!)
     *
     * - Mouse click:
     *   Pressing the button can cause the editor to lose focus which can
     *   lead to wacky Slate behaviour (e.g. selection out of sync with DOM)
     */
    yield call(preventDefault, payload.event);

    yield put(requestFinaliseDictation());
    yield take(finalisedDictation);

    const handler = match(payload)
      .with(
        { name: "bold" },
        { name: "italic" },
        { name: "underline" },
        ({ name, event }) => {
          return function* () {
            yield call(handleStyleFormattingButtons, name, event);
          };
        }
      )
      .with({ name: "undo" }, { name: "redo" }, ({ name, event }) => {
        return function* () {
          yield call(handleHistoryButtons, name, event);
        };
      })
      .with({ name: "clear" }, ({ event }) => {
        return function* () {
          yield call(handleClearButton, event);
        };
      })
      .with({ name: "copy" }, ({ event }) => {
        return function* () {
          yield call(handleCopyButton, event);
        };
      })
      .exhaustive();

    yield call(handler);
  });
};
