import { createSelector } from "@reduxjs/toolkit";

import { selectDictationStatus } from "@talktype/editor";
import { selectActiveApp } from "@talktype/system";

export const selectIsDictatingToApp = createSelector(
  selectDictationStatus,
  selectActiveApp,
  (dictationStatus, activeApp) =>
    dictationStatus === "active" && !activeApp.isSelf
);
