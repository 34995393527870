import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./layout.module.scss";

export type LayoutProps = {
  header: ReactNode;
  formattingToolbar: ReactNode;
  editor: ReactNode;
  dictationControls: ReactNode;
  drawer: {
    isOpen: boolean;
    component: ReactNode;
  };
  notifications: ReactNode;
};

export const Layout = ({
  header,
  editor,
  formattingToolbar,
  dictationControls,
  drawer,
  notifications,
}: LayoutProps): ReactElement => (
  <main
    className={classNames(
      styles.container,
      createSelectorClassName("dictate-to-talktype", "section")
    )}
    tabIndex={-1}
  >
    {header}

    <div
      className={classNames(styles.contentArea, [
        drawer.isOpen,
        styles.withDrawer,
      ])}
    >
      {drawer.component}

      <div className={styles.editorWorkspace}>
        <div className={styles.formattingBar}>{formattingToolbar}</div>
        <div className={styles.editor}>{editor}</div>
        <div>
          <div className={styles.notifications}>{notifications}</div>
          <div className={styles.dictationControls}>{dictationControls}</div>
        </div>
      </div>
    </div>
  </main>
);
