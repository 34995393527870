import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call } from "redux-saga/effects";

import { Storage } from "@carescribe/utilities/src/Storage";
import { StrictJSON } from "@carescribe/utilities/src/StrictJSON";

import { isUserState } from "../../guards/isUserState";
import { setUser } from "../../reducer";
import { logError } from "../../utils/log";

/**
 * Get User From Local Storage
 *
 * Gets stored user data from local storage and sets it in the redux store
 */
export const getUserFromLocalStorage = function* (): SagaIterator<void> {
  const storedUser: SagaReturnType<typeof Storage.getItem> = yield call(
    Storage.getItem,
    { key: "user", app: "talktype" }
  );

  if (!storedUser) {
    yield put(setUser({}));
    return;
  }

  const [parsedUserState]: SagaReturnType<typeof StrictJSON.safeParse> =
    yield call(StrictJSON.safeParse, storedUser);

  if (isUserState(parsedUserState)) {
    yield put(setUser(parsedUserState));
  } else {
    yield put(setUser({}));
    yield call(
      logError,
      "Invalid user state in found in local storage:",
      parsedUserState
    );
  }
};
