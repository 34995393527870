import type { SagaIterator } from "redux-saga";

import { call } from "redux-saga/effects";

import { setUpPkce } from "@carescribe/pkce/src/sagas/setUpPkce";
import { getAppContext } from "@carescribe/utilities/src/getAppContext";
import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { loginDeepLink } from "@talktype/config/src/loginDeepLink";
import { scopes } from "@talktype/config/src/scopes";

import { CLIENT_ID, DASHBOARD_URL } from "../env";

const getRedirectUri = (): string =>
  getAppContext() === "desktop"
    ? loginDeepLink
    : (window.location.origin + window.location.pathname).replace(/\/$/, "");

const getAuthUrl = (): string => {
  const authUrl = new URL(DASHBOARD_URL);
  authUrl.pathname = "/talktype/oauth";
  return authUrl.toString();
};

export const configurePkce = function* (): SagaIterator<void> {
  yield retry(function* (): SagaIterator<void> {
    yield call(
      setUpPkce,
      {
        scopes,
        additionalParams: { redirect_uri: getRedirectUri() },
        authUrl: getAuthUrl(),
        clientId: CLIENT_ID,
      },
      "talktype"
    );
  });
};
