import type { RootState } from "./types";
import type { CombinedState, Reducer } from "@reduxjs/toolkit";

import { combineReducers } from "@reduxjs/toolkit";

import { reducer as pkce } from "@carescribe/pkce/src/reducer";

import { reducer as analytics } from "@talktype/analytics";
import { reducer as dictateToApp } from "@talktype/dictate-to-app";
import { reducer as editor } from "@talktype/editor";
import { reducer as featureFlags } from "@talktype/feature-flags";
import { reducer as listing } from "@talktype/listing/src/reducer";
import { reducer as preferences } from "@talktype/preferences";
import { reducer as system } from "@talktype/system";
import { reducer as toasts } from "@talktype/toasts/src/reducer";
import { reducer as user } from "@talktype/user/src/reducer";

import { replaceState } from "./actions";

export const reducer: Reducer<CombinedState<ReturnType<typeof reducers>>> = (
  state,
  action
): RootState => {
  if (action.type === replaceState.type) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return reducers(state, action);
};

export const reducers = combineReducers({
  analytics,
  dictateToApp,
  editor,
  featureFlags,
  listing,
  preferences,
  system,
  user,
  pkce,
  toasts,
});
