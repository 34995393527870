import type { Callback } from "@carescribe/types";
import type { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import type { Action } from "redux";
import type { SagaIterator, EventChannel } from "redux-saga";

import { eventChannel } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";

type SyncActionsAcrossWindows = ({
  channelName,
  broadcastAction,
}: {
  channelName: string;
  broadcastAction: ActionCreatorWithPayload<Action>;
}) => Callback<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [ActionCreatorWithPayload<any>, ActionCreatorWithPayload<any>][],
  SagaIterator<void>
>;

/**
 * Creates a saga which syncs actions across windows (& tabs).
 *
 * Two requirements to get setup:
 *
 * 1. Channel name: constant, unique name for the broadcast channel
 * used to communicate between windows..
 *
 * 2. Broadcast action: action creator which creates an action that
 * is used for wrapping actions we want broadcasted to other tabs.
 */
export const syncActionsAcrossWindows: SyncActionsAcrossWindows = ({
  channelName,
  broadcastAction,
}) =>
  function* () {
    if (!("BroadcastChannel" in window)) {
      return;
    }

    const broadcastChannel = new BroadcastChannel(channelName);

    const broadcastEventChannel: EventChannel<Action> = yield call(
      eventChannel,
      (emit) => {
        const messageHandler = ({ data }: { data: Action }): void => emit(data);

        broadcastChannel.addEventListener("message", messageHandler);
        return () => {
          broadcastChannel.removeEventListener("message", messageHandler);
          broadcastChannel.close();
        };
      }
    );

    // Broadcast actions
    yield takeEvery(
      broadcastAction,
      function* ({ payload: action }): SagaIterator<void> {
        /**
         * Using [context, method] syntax necessary to ensure 'this'
         * inside postMessage is correctly preserved as 'broadcastChannel'.
         */
        yield call([broadcastChannel, broadcastChannel.postMessage], action);
        yield put(action);
      }
    );

    // Receive actions
    yield takeEvery(
      broadcastEventChannel,
      function* (action): SagaIterator<void> {
        yield put(action);
      }
    );
  };
