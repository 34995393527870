import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { deleteCustomShortcut } from "../../reducers/preferences";
import { broadcast, requestDeleteCustomShortcut } from "../actions";

/**
 * Handles requests to delete a custom shortcut.
 */
export const manageDeleteCustomShortcut = function* (): SagaIterator<void> {
  yield takeEvery(requestDeleteCustomShortcut, function* ({ payload: id }) {
    yield put(broadcast(deleteCustomShortcut(id)));
  });
};
