import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { select } from "redux-saga/effects";

import { Storage } from "@carescribe/utilities/src/Storage";

import { selectUUID } from "@talktype/user/src/reducer/selectors/selectUUID";

const app = "talktype";

export const UserStorage = {
  getItem: function* (
    key: string
  ): SagaIterator<SagaReturnType<typeof Storage.getItem>> {
    const id: SagaReturnType<typeof selectUUID> = yield select(selectUUID);
    return Storage.getItem({ key, id, app });
  },

  removeItem: function* (key: string): SagaIterator<void> {
    const id: SagaReturnType<typeof selectUUID> = yield select(selectUUID);
    Storage.removeItem({ key, id, app });
  },

  setItem: function* (key: string, value: string): SagaIterator<void> {
    const id: SagaReturnType<typeof selectUUID> = yield select(selectUUID);
    Storage.setItem({ key, id, app }, value);
  },
};
