import type { RootState } from "@talktype/store";
import type { ToastsContainerProps } from "@talktype/toasts/src/components/ToastsContainer";
import type { Toast as ToastType } from "@talktype/types";
import type { ComponentType } from "react";

import { connect } from "react-redux";

import { ToastsContainer as Component } from "@talktype/toasts/src/components/ToastsContainer";
import { selectToasts } from "@talktype/toasts/src/reducer/selectors/selectToasts";

import { Toast } from "./Toast";

const mapStateToProps = (
  state: RootState
): Pick<ToastsContainerProps<ToastType>, "toasts"> => ({
  toasts: selectToasts(state),
});

const mapDispatchToProps: Pick<ToastsContainerProps<ToastType>, "Toast"> = {
  Toast,
};

export const ToastsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
  // Assertion necessary in order to correctly type the component's generic
)(Component as ComponentType<ToastsContainerProps<ToastType>>);
