import type { AppDispatch, RootState } from "@talktype/store";
import type { DictationHistoryEntry } from "@talktype/types";
import type { DictationHistoryPopulatedProps } from "@talktype/ui/src/DictationHistoryPopulated";

import { connect } from "react-redux";

import {
  selectDictationHistoryEntries,
  requestCopyDictationHistoryEntry,
} from "@talktype/dictate-to-app";
import { DictationHistoryPopulated as Component } from "@talktype/ui/src/DictationHistoryPopulated";

type StateProps = { entries: DictationHistoryEntry[] };

const mapStateToProps = (state: RootState): StateProps => ({
  entries: selectDictationHistoryEntries(state),
});

type DispatchProps = {
  onCopy: ({ resultId }: { resultId: string }) => void;
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onCopy: (entry) => dispatch(requestCopyDictationHistoryEntry(entry)),
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps
): DictationHistoryPopulatedProps => {
  const entries = stateProps.entries.map((entry) => ({
    ...entry,
    onCopy: () => dispatchProps.onCopy(entry),
  }));

  return { entries };
};

export const DictationHistoryPopulated = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
