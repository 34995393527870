import type { EventTrackingRequest } from "../types";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

const action = prefixActionCreator("saga/talktype-analytics");

export const requestTrackEvent = action<
  EventTrackingRequest,
  "request-track-event"
>("request-track-event");

export const trackedTalkTypeEvent = action<
  EventTrackingRequest,
  "tracked-tt-event"
>("tracked-tt-event");
