import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, select, takeEvery } from "redux-saga/effects";

import { Storage } from "@carescribe/utilities/src/Storage";

import { actions } from "../../reducer";
import { selectPkce } from "../../reducer/selectors/selectPkce";

export const setUpStorePkceInLocalStorage = function* (
  appName: string
): SagaIterator<void> {
  yield takeEvery(Object.values(actions), function* (): SagaIterator<void> {
    const state: SagaReturnType<typeof selectPkce> = yield select(selectPkce);

    const stateAsString: SagaReturnType<typeof JSON.stringify> = yield call(
      [JSON, "stringify"],
      state
    );

    yield call(Storage.setItem, { key: "pkce", app: appName }, stateAsString);
  });
};
