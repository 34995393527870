import type { CommandConfig } from "../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { clear as performClear } from "../../../sagas/commands/clear/clear";
import { createCommandDefinition } from "../utils/createCommandDefinition";

export const clear: CommandConfig = createCommandDefinition({
  id: "clear",
  matchers: messages.matchers,
  type: "action",
  deadline: toSeconds(1),
  messages,
  performCommand: { talktype: performClear, app: null },
  input: messages.listing,
  track: { name: "Text Cleared", data: {} },
});
