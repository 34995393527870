import type { Void } from "@carescribe/types";

import { noOp } from "./fp";
import { isTest } from "./testing/isTest";

type ConsoleParams = Parameters<typeof console.info>;

export const createLogger = (
  scope: string
): {
  log: (...messages: ConsoleParams) => void;
  logError: (...messages: ConsoleParams) => void;
  logWarning: (...messages: ConsoleParams) => void;
  logGroup: (...messages: ConsoleParams) => void;
  logGroupCollapsed: (...messages: ConsoleParams) => void;
  logGroupEnd: Void;
} =>
  isTest()
    ? {
        log: noOp,
        logError: noOp,
        logWarning: noOp,
        logGroup: noOp,
        logGroupCollapsed: noOp,
        logGroupEnd: noOp,
      }
    : {
        log: (...messages): void => {
          console.info(scope, "|", ...messages);
        },
        logError: (...messages): void => {
          console.error(
            scope,
            "|",
            ...messages.map((message) =>
              message instanceof Error ? message.message : message
            )
          );
        },
        logWarning: (...messages): void => {
          console.warn(scope, "|", ...messages);
        },
        logGroup: (...messages): void => {
          console.group(scope, "|", ...messages);
        },
        logGroupCollapsed: (...messages): void => {
          console.groupCollapsed(scope, "|", ...messages);
        },
        logGroupEnd: (): void => {
          console.groupEnd();
        },
      };
