import type { BaseEditor } from "slate";
import type { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";

import { Editor } from "slate";

import { elementIsFocusable } from "@carescribe/utilities/src/guards/elementIsFocusable";

import { focusEditor } from "./focusEditor";

/**
 * Given a marks object, sets a mark at the current selection.
 *
 * In an ideal world, this would be a one-liner:
 *
 * ```
 * Editor.addMark(editor, mark, value)
 * ```
 *
 * Due to a {@link https://github.com/ianstormtaylor/slate/issues/4494 Slate bug},
 * we must do some selection manipulation to avoid Slate's selection getting out
 * of sync with the browser's selection.
 *
 * When marks are added/removed, the structure of the document changes. Slate
 * automatically updates the selection and syncs it with the browser's own DOM
 * selection. Surprise, surprise, this does not happen if the editor is not in
 * focus (e.g. tabbing into formatting buttons and pressing them via keyboard).
 * And so, we focus the editor, add the mark, and then focus the previously
 * active element.
 *
 * @example
 * // To set "bold" as `true`
 * setMark(editor, 'bold', true)
 */
export const setMark = async (
  editor: ReactEditor,
  mark: string,
  value: boolean
): Promise<void> => {
  const activeElement = document.activeElement;

  focusEditor(editor);

  /**
   * Must finish adding the mark before we can actually move on, otherwise this
   * results in the editor maintaining focus.
   *
   * Unfortunately, Slate's API does not seem to provide a nicer way to do this
   * so we have to resort to this workaround. It is a promise that resolves
   * once the mark has actually been added.
   *
   * Steps:
   * 1. Grab the original `onChange` handler
   * 2. Override editor's `onChange` with our own
   *   - Executes logic from original `onChange` to retain that behaviour
   *   - Resets the `onChange` handler to the original
   *   - Resolves the promise
   * 3. Add the mark
   */
  await new Promise((resolve) => {
    const originalOnChange = editor.onChange;

    editor.onChange = (options): void => {
      originalOnChange(options);
      editor.onChange = originalOnChange;
      resolve(null);
    };

    Editor.addMark(
      /**
       * Shouldn't need to cast here, remove it and no linting type errors show
       * up. However, running the CI checks otherwise result in a type error:
       *
       * `TS2345: Argument of type 'ReactEditor' is not assignable to parameter
       * of type 'BaseEditor & ReactEditor & HistoryEditor'`
       *
       * Possibly just resolution shenanigans..
       */
      editor as BaseEditor & ReactEditor & HistoryEditor,
      mark,
      value
    );
  });

  if (elementIsFocusable(activeElement)) {
    activeElement.focus();
  }
};
