import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { requestFinaliseDictation } from "@talktype/actions";
import { setActiveApp } from "@talktype/system";

/**
 * Finalise dictation when active app changes.
 */
export const finaliseWhenActiveAppChanges = function* (): SagaIterator<void> {
  yield takeEvery(setActiveApp, function* (): SagaIterator<void> {
    yield put(requestFinaliseDictation());
  });
};
