import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { selectPhrase as performSelectPhrase } from "../../../../sagas/commands/selection/selectPhrase";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const selectPhrase: CommandConfig = createCommandDefinition({
  id: "selectPhrase",
  matchers: messages.matchers,
  type: "action",
  deadline: toSeconds(5),
  messages,
  performCommand: { talktype: performSelectPhrase, app: null },
  input: messages.listing,
  track: { name: "Text Selected", data: { selectionType: "phrase" } },
});
