import type { Tokens } from "../types/Tokens";

import { P } from "ts-pattern";

import { createGuard } from "@carescribe/utilities/src/guards/createGuard";

const validSeconds = {
  unit: "seconds",
  magnitude: P.number,
  quantity: "time",
} as const;

export const validTokens = {
  accessToken: P.string,
  refreshToken: P.string,
  expiresAt: validSeconds,
  scope: "dictate",
  tokenType: "Bearer",
} as const;

export const isTokens = createGuard<Tokens>(validTokens);
