import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { selectRange as performSelectRange } from "../../../../sagas/commands/selection/selectRange";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const selectRange: CommandConfig = createCommandDefinition({
  id: "selectRange",
  matchers: messages.matchers,
  type: "action",
  deadline: toSeconds(5),
  messages,
  performCommand: {
    talktype: performSelectRange({ from: messages.from, to: messages.to }),
    app: null,
  },
  input: messages.listing,
  track: {
    name: "Text Selected",
    data: { selectionType: "range" },
    keywords: messages.to,
  },
});
