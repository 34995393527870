import type { SagaIterator } from "redux-saga";

import { call, takeEvery } from "redux-saga/effects";

import { UserStorage } from "@talktype/utilities/src/UserStorage";

import { setDictationMode } from "../../reducers";

/**
 * Saves the dictation mode to local storage whenever it changes.
 */
export const setDictationModeInLocalStorage = function* (): SagaIterator<void> {
  yield takeEvery(setDictationMode, function* ({ payload: mode }) {
    yield call(UserStorage.setItem, "dictation_mode", mode);
  });
};
