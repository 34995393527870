import type { DictationHistoryEntry as DictationHistoryEntryType } from "@talktype/types";
import type { ReactElement } from "react";

import { ChatCircle } from "@phosphor-icons/react";
import { useEffect, useRef } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { prefersReducedMotion } from "@carescribe/utilities/src/mediaQueries/prefersReducedMotion";

import styles from "./dictationHistoryPopulated.module.scss";
import { messages } from "./messages";
import { DictationHistoryEntry } from "../DictationHistoryEntry";

export type DictationHistoryPopulatedProps = {
  entries: (DictationHistoryEntryType & { onCopy: () => void })[];
};

export const DictationHistoryPopulated = ({
  entries,
}: DictationHistoryPopulatedProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }
    const top = container.scrollHeight;
    const behavior = prefersReducedMotion() ? "auto" : "smooth";

    container.scrollTo({ top, behavior });
  }, [entries]);

  return (
    <div className={classNames(styles.container)} ref={containerRef}>
      {entries.map((entry) => (
        <DictationHistoryEntry
          key={entry.resultId}
          icon={<ChatCircle />}
          text={entry.text}
          isFinal={entry.isFinal}
          onCopy={entry.onCopy}
          description={messages[entry.isFinal ? "sentTo" : "dictatingTo"](
            entry.targetApp.name
          )}
        />
      ))}
    </div>
  );
};
