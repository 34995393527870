import type { EventTrackingRequest } from "../types";
import type { TrackEvent } from "@carescribe/analytics/src/trackEvent";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { trackEvent } from "@carescribe/analytics/src/trackEvent";

import { trackedTalkTypeEvent } from "./actions";
import { createGenericEventDataSelector } from "../selectors/createGenericEventDataSelector";

export const setUpTrackTalkTypeEvents = function* (
  genericEventData: Record<string, unknown>
): SagaIterator<void> {
  const selectGenericEventData: SagaReturnType<
    typeof createGenericEventDataSelector
  > = yield call(createGenericEventDataSelector, genericEventData);

  type TrackEventTypeConfig = TrackEvent<
    Parameters<typeof selectGenericEventData>[0],
    EventTrackingRequest
  >;

  yield call<TrackEventTypeConfig>(
    trackEvent,
    selectGenericEventData,
    trackedTalkTypeEvent
  );
};
