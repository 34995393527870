import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { deleteSelection as performDeleteSelection } from "../../../../sagas/commands/selection/deleteSelection";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const deleteSelection: CommandConfig = createCommandDefinition({
  id: "deleteSelection",
  matchers: messages.matchers,
  type: "action",
  deadline: toSeconds(0),
  messages,
  performCommand: { talktype: performDeleteSelection, app: null },
  input: messages.listing,
  track: { name: "Text Deleted", data: { deletionType: "selection" } },
});
