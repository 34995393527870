import type { PkceState } from "../types/PkceState";

import { P } from "ts-pattern";

import { createGuard } from "@carescribe/utilities/src/guards/createGuard";

import { validPkceCodePair } from "./isPkceCodePair";
import { validTokens } from "./isTokens";

export const isPkceState = createGuard<PkceState>({
  authUrl: P.string,
  additionalParams: {},
  scopes: P.array(P.string),
  clientId: P.string,
  tokens: P.union(validTokens, P.nullish),
  pkceCodePair: P.union(validPkceCodePair, P.nullish),
});
