import type { Reshaper } from "./types/Reshaper";

/**
 * Reshape Share Anonymised Usage
 *
 * Convert legacy share anonymised usage and copy into preferences.
 */
export const reshapeUsageShare: Reshaper =
  (legacyPreferences) => (newPreferences) =>
    typeof legacyPreferences.usage_share !== "boolean"
      ? newPreferences
      : {
          ...newPreferences,
          shareAnonymisedUsage: legacyPreferences.usage_share,
        };
