import type { AppDispatch, RootState } from "@talktype/store";
import type { FilterProfanityFieldProps } from "@talktype/ui/src/PreferencesModal/FilterProfanityField";
import type { ChangeEventHandler } from "react";

import { connect } from "react-redux";

import {
  broadcast,
  selectFilterProfanity,
  setFilterProfanity,
} from "@talktype/preferences";
import { FilterProfanityField as Component } from "@talktype/ui/src/PreferencesModal/FilterProfanityField";

type StateProps = { checked: boolean };

type DispatchProps = { onChange: ChangeEventHandler<HTMLInputElement> };

const mapStateToProps = (state: RootState): StateProps => ({
  checked: selectFilterProfanity(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onChange: (e) =>
    dispatch(broadcast(setFilterProfanity(e.currentTarget.checked))),
});

const mergeProps = (
  { checked }: StateProps,
  { onChange }: DispatchProps
): FilterProfanityFieldProps => ({
  checked,
  onChange,
});

export const FilterProfanityField = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
