import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, take, takeEvery } from "redux-saga/effects";

import { processedResult } from "@talktype/actions/src";

import {
  requestCancelTask,
  requestTrackPrompt,
  trackedPrompt,
} from "./actions";
import { performActionCommand } from "./performActionCommand";
import { taskRef } from "./task/trackTask";
import { isActionCommand } from "../guards/isActionCommand";
import { isPromptWithCommand } from "../guards/isPromptWithCommand";

/**
 * This saga listens for any final results and performs finalisation
 * behaviours relating to voice commands.
 *
 * These include:
 *
 * - performing the action (action commands only)
 * - cancelling the task
 */
export const finaliseVoiceCommands = function* (): SagaIterator<void> {
  const { payload: task }: SagaReturnType<typeof taskRef> = yield take(taskRef);

  yield takeEvery(
    processedResult,
    function* ({ payload: { isFinal, resultId, targetApp } }) {
      if (!task.current.prompt) {
        // Order of this condition and the next is important. If we return on
        // final results without cancelling the task, it can cause the
        // listening UI to persist forever.
        yield put(requestCancelTask());
        return;
      }

      if (!isFinal) {
        return;
      }

      if (
        isPromptWithCommand(task.current.prompt) &&
        isActionCommand(task.current.prompt.command)
      ) {
        yield call(performActionCommand, {
          resultId,
          prompt: task.current.prompt,
          targetApp,
        });

        yield put(requestTrackPrompt(null));
        yield take(trackedPrompt);
      }

      yield put(requestCancelTask());
    }
  );
};
