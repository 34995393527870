import type { SagaIterator } from "redux-saga";

import { spawn } from "redux-saga/effects";

import { initSpa } from "@carescribe/sentry/src/initSpa";

import { SENTRY_DSN, SENTRY_ENV } from "../env";
import { getBuildId } from "../utils/getBuildId";

export const configureSentry = function* (): SagaIterator<void> {
  yield spawn(initSpa, {
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    initialScope: { tags: { buildId: getBuildId() } },
  });
};
