import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { listenWindowIsMaximisedChanges } from "./listenWindowIsMaximisedChanges";
import { manageActiveAppTracking } from "./manageActiveAppTracking";
import { manageWindowControls } from "./manageWindowControls";
import { trackActiveAppSubscriptionState } from "./trackActiveAppSubscriptionState";

export const setUpSystem = function* (): SagaIterator<void> {
  yield all(
    [
      manageActiveAppTracking,
      manageWindowControls,
      trackActiveAppSubscriptionState,
      listenWindowIsMaximisedChanges,
    ].map(retry)
  );
};
