import type { EventChannel, SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { eventChannel } from "redux-saga";
import {
  call,
  delay,
  race,
  take,
  takeLatest,
  select,
} from "redux-saga/effects";

import { UserStorage } from "@talktype/utilities/src/UserStorage";

import { stringifyDocument } from "./stringifyDocument";
import { selectDocument, setDocument } from "../../reducers";

export const saveDocument = function* (): SagaIterator<void> {
  const document: SagaReturnType<typeof selectDocument> = yield select(
    selectDocument
  );

  const documentAsString: SagaReturnType<typeof stringifyDocument> = yield call(
    stringifyDocument,
    document
  );

  yield call(UserStorage.setItem, "document", documentAsString);
};

/**
 * Creates an event channel that emits events when the app becomes hidden.
 * This is detected by listening for `visibilitychange`.
 */
export const createAppHiddenEventChannel = (): EventChannel<Event> =>
  eventChannel((emit) => {
    const onVisibilityChange = (event: Event): void => {
      if (document.visibilityState === "visible") {
        return;
      }
      emit(event);
    };

    document.addEventListener("visibilitychange", onVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  });

/**
 * Manages document saving of the document in local storage.
 *
 * Triggers autosave on document changes. Saving is debounced
 * for performance reasons (500ms), but will kick in instantly if the
 * app is closing/becomes hidden.
 */
export const setDocumentInLocalStorage = function* (): SagaIterator<void> {
  const appHidden: SagaReturnType<typeof createAppHiddenEventChannel> =
    yield call(createAppHiddenEventChannel);

  yield takeLatest(setDocument, function* () {
    yield race([delay(500), take(appHidden)]);

    yield call(saveDocument);
  });
};
