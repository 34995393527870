import type { EditorType } from "../editor";
import type {
  InlineStyleMarks,
  InlineStyles,
  Marks,
  NonStylingButtonNames,
  AppTargetType,
} from "@talktype/types";
import type { KeyboardEvent, MouseEvent, ClipboardEvent } from "react";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/talktype-editor");

export const editorLoaded = action<EditorType, "loaded">("loaded");

export const editorUnloaded = action<void, "unloaded">("unloaded");

export const requestEditor = action<void, "request-editor">("request-editor");

export const broadcastEditor = action<EditorType | null, "broadcast-editor">(
  "broadcast-editor"
);

export const editorChanged = action<EditorType["operations"], "changed">(
  "changed"
);

export const editorErrored = action<Error, "errored">("errored");

export const editorClicked = action<MouseEvent, "clicked">("clicked");

export const editorKeyUp = action<KeyboardEvent, "keyup">("keyup");

export const editorCut = action<ClipboardEvent, "cut">("cut");

export const editorCopy = action<ClipboardEvent, "copy">("copy");

export const editorPaste = action<ClipboardEvent, "paste">("paste");

export const startDictating = action<void, "start-dictating">(
  "start-dictating"
);

export const editorKeyDown = action<KeyboardEvent, "key-down">("key-down");

export const requestToggleInlineStyle = action<
  InlineStyles,
  "request-toggle-inline-style"
>("request-toggle-inline-style");

export const requestBroadcastInlineStyles = action<
  void,
  "request-broadcast-inline-styles"
>("request-broadcast-inline-styles");

export const broadcastedInlineStyles = action<
  InlineStyleMarks,
  "broadcasted-inline-styles"
>("broadcasted-inline-styles");

export const requestPressEnter = action<
  { target: AppTargetType },
  "request-press-enter"
>("request-press-enter");

export const pressedEnter = action<boolean, "pressed-enter">("pressed-enter");

export const requestSetInlineStyle = action<
  [InlineStyles, boolean],
  "set-inline-style"
>("set-inline-style");

export const inlineStyleSet = action<
  [InlineStyles, boolean],
  "inline-style-set"
>("inline-style-set");

export const requestSelectPhrase = action<string, "request-select-phrase">(
  "request-select-phrase"
);

export const selectedPhrase = action<boolean, "selected-phrase">(
  "selected-phrase"
);

export const requestSelectRange = action<
  { from: string; to: string },
  "request-select-range"
>("request-select-range");

export const selectedRange = action<boolean, "selected-range">(
  "selected-range"
);

export const requestSaveEditorMarks = action<void, "request-save-editor-marks">(
  "request-save-editor-marks"
);

export const savedEditorMarks = action<void, "saved-editor-marks">(
  "saved-editor-marks"
);

export const requestClearSavedEditorMarks = action<
  void,
  "request-clear-saved-editor-marks"
>("request-clear-saved-editor-marks");

export const clearedSavedEditorMarks = action<
  void,
  "cleared-saved-editor-marks"
>("cleared-saved-editor-marks");

export const requestEditorMarks = action<void, "request-editor-marks">(
  "request-editor-marks"
);

export const requestRestoreMarks = action<void, "request-restore-marks">(
  "request-restore-marks"
);

export const editorToggledMark = action<boolean, "editor-toggled-mark">(
  "editor-toggled-mark"
);

export const editorFormattingBarInteracted = action<
  {
    name: InlineStyles | NonStylingButtonNames;
    event: KeyboardEvent | MouseEvent;
  },
  "editor-formatting-bar-interacted"
>("editor-formatting-bar-interacted");

export const requestHistory = action<"undo" | "redo", "request-history">(
  "request-history"
);

export const historyUpdated = action<boolean, "history-updated">(
  "history-updated"
);

export const requestClearEditorContents = action<
  void,
  "request-clear-editor-contents"
>("request-clear-editor-contents");

export const clearedEditorContents = action<boolean, "cleared-editor-contents">(
  "cleared-editor-contents"
);

export const requestCopyEditorContents = action<
  void,
  "request-copy-editor-contents"
>("request-copy-editor-contents");

export const editorContentsCopied = action<
  { characterCount: number },
  "editor-contents-copied"
>("editor-contents-copied");

export const restoredMarks = action<void, "restored-marks">("restored-marks");

export const broadcastEditorMarks = action<
  Marks | null,
  "broadcast-editor-marks"
>("broadcast-editor-marks");

export const requestDisableSaving = action<void, "request-disable-saving">(
  "request-disable-saving"
);

export const requestEnableSaving = action<void, "request-enable-saving">(
  "request-enable-saving"
);

export const savingDisabled = action<void, "saving-disabled">(
  "saving-disabled"
);

export const savingEnabled = action<void, "saving-enabled">("saving-enabled");

export const requestDeleteSelection = action<void, "request-delete-selection">(
  "request-delete-selection"
);

export const selectionDeleted = action<boolean, "selection-deleted">(
  "selection-deleted"
);

export const requestDeletePhrase = action<string, "request-delete-phrase">(
  "request-delete-phrase"
);

export const deletedPhrase = action<boolean, "deleted-phrase">(
  "deleted-phrase"
);

export const requestExecuteBackspace = action<
  void,
  "request-execute-backspace"
>("request-execute-backspace");

export const executedBackspace = action<void, "executed-backspace">(
  "executed-backspace"
);

export const requestDeleteRange = action<
  { from: string; to: string },
  "request-delete-range"
>("request-delete-range");

export const deletedRange = action<boolean, "deleted-range">("deleted-range");

export const requestCursorMove = action<
  "up" | "down" | "left" | "right",
  "request-cursor-move"
>("request-cursor-move");

export const movedCursor = action<boolean, "moved-cursor">("moved-cursor");

export const requestClearInProgressRange = action<
  void,
  "request-clear-in-progress-range"
>("request-clear-in-progress-range");

export const clearedInProgressRange = action<void, "cleared-in-progress-range">(
  "cleared-in-progress-range"
);
