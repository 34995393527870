import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call } from "redux-saga/effects";

import { Storage } from "@carescribe/utilities/src/Storage";
import { StrictJSON } from "@carescribe/utilities/src/StrictJSON";

import { isPkceState } from "../../guards/isPkceState";
import { setPkce } from "../../reducer";
import { logError } from "../../utils/log";

export const getPkceFromLocalStorage = function* (
  app: string
): SagaIterator<void> {
  const storedState: SagaReturnType<typeof Storage.getItem> = yield call(
    Storage.getItem,
    { key: "pkce", app }
  );

  if (!storedState) {
    yield put(setPkce({}));
    return;
  }

  const [parsedPkceState]: SagaReturnType<typeof StrictJSON.safeParse> =
    yield call(StrictJSON.safeParse, storedState);

  if (isPkceState(parsedPkceState)) {
    yield put(setPkce(parsedPkceState));
  } else {
    yield put(setPkce({}));
    yield call(
      logError,
      "Invalid PKCE state found in local storage:",
      storedState
    );
  }
};
