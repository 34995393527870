import type { Me } from "../types/Me";

import { P } from "ts-pattern";

import { createGuard } from "@carescribe/utilities/src/guards/createGuard";

export const validMe = {
  createdAt: P.string,
  email: P.string,
  id: P.number,
  updatedAt: P.string,
  uuid: P.string,
};

export const isMe = createGuard<Me>(validMe);
