import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call, takeEvery } from "redux-saga/effects";

import { StrictJSON } from "@carescribe/utilities/src/StrictJSON";

import { UserStorage } from "@talktype/utilities/src/UserStorage";

import { isValidPreferences } from "../../guards/isValidPreferences";
import {
  gotPreferencesFromLocalStorage,
  requestPreferencesFromLocalStorage,
} from "../actions";

/**
 * Gets the preferences from local storage and sets them in the store.
 */
export const getPreferencesFromLocalStorage = function* (): SagaIterator<void> {
  yield takeEvery(requestPreferencesFromLocalStorage, function* () {
    const storedPreferences: SagaReturnType<typeof UserStorage.getItem> =
      yield call(UserStorage.getItem, "preferences");

    if (!storedPreferences) {
      yield put(gotPreferencesFromLocalStorage(null));
      return;
    }

    const [parsedPreferences]: SagaReturnType<typeof StrictJSON.safeParse> =
      yield call(StrictJSON.safeParse, storedPreferences);

    const validatedPreferences = isValidPreferences(parsedPreferences)
      ? parsedPreferences
      : {};

    yield put(gotPreferencesFromLocalStorage(validatedPreferences));
  });
};
