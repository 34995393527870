import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, select, takeEvery } from "redux-saga/effects";

import { UserStorage } from "@talktype/utilities/src/UserStorage";

import { selectPreferences, actions } from "../../reducers/preferences";

/**
 * Sets the preferences in local storage.
 */
export const setPreferencesInLocalStorage = function* (): SagaIterator<void> {
  yield takeEvery(Object.values(actions), function* (): SagaIterator<void> {
    const preferences: SagaReturnType<typeof selectPreferences> = yield select(
      selectPreferences
    );

    const preferencesAsString: SagaReturnType<typeof JSON.stringify> =
      yield call([JSON, "stringify"], preferences);

    yield call(UserStorage.setItem, "preferences", preferencesAsString);
  });
};
