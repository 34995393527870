/**
 * Why not grab this from `./env` as we do for other env variables?
 *
 * Other required env variables in that file are not ready at the time this
 * function is called.
 * Defining `REACT_APP_BUILD_ID` there would lead to a build error.
 */
const SHORT_SHA = process.env.REACT_APP_BUILD_ID ?? "";

/**
 * Responsible for retrieving the app's build ID.
 *
 * This is done by attempting to retrieve the `REACT_APP_BUILD_ID` environment
 * variable.
 */
export const getBuildId = (): string => SHORT_SHA;
