export const messages = {
  punctuation: {
    title: "Punctuation & special characters",
    basics: { title: "Basics" },
    advanced: { title: "Advanced" },
    brackets: { title: "Brackets" },
    mathematics: { title: "Mathematics" },
    currency: { title: "Currency" },
  },

  textEditing: {
    title: "Text editing",
    formatting: { title: "Formatting" },
    selecting: { title: "Selecting" },
    deleting: { title: "Deleting" },
  },

  textNavigation: {
    title: "Text navigation",
    lineBreaks: { title: "Line breaks" },
    keyStrokes: { title: "Keystrokes" },
  },

  system: {
    title: "System",
    appCommands: { title: "App commands" },
  },

  replacementPrompt: "While dictating, saying...",
  insertionPrompt: "While dictating, say...",
  commandPrompt: "Say TalkType...",
};
