import type { PayloadAction } from "@reduxjs/toolkit";
import type { Preferences } from "@talktype/types";

type SetShareAnonymisedUsage = (
  state: Preferences,
  action: PayloadAction<boolean>
) => void;

/**
 * Handles the setting of shareAnonymisedUsage.
 */
export const setShareAnonymisedUsage: SetShareAnonymisedUsage = (
  state,
  { payload }
) => {
  state.shareAnonymisedUsage = payload;
};
