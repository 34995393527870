import type { Listing } from "@talktype/types/src/Listing";

import produce from "immer";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";
import { ucFirst } from "@carescribe/utilities/src/ucFirst";

import { commands } from "@talktype/commands/src/config/commands";
import {
  newLine,
  newParagraph,
} from "@talktype/config/src/transformationSettings";
import * as punctuation from "@talktype/transcript-transformation/src/config/punctuation";

import { messages } from "./messages";

type Section<Type> = {
  id: string;
  title: string;
  subsections: Type[];
};

export type TopLevelSection<Type> = Section<Type> & {
  icon: string;
};

export type SubSection<Type> = Section<Type> & {
  subtitle: string;
};

export type ListingStructure = TopLevelSection<SubSection<Listing>>[];

export const uncapitaliseListingStructure = produce(
  (listings: ListingStructure): void => {
    listings.forEach((listing) => {
      listing.title = ucFirst(listing.title);
      listing.subsections.forEach((subsection) => {
        subsection.subsections.forEach((subsubsection) => {
          subsection.title = ucFirst(subsection.title);
          subsubsection.input = subsubsection.input.map((value) =>
            value.toLowerCase()
          );
        });
      });
    });
  }
);

export const listingStructure: ListingStructure = [
  {
    title: messages.punctuation.title,
    id: "punctuation",
    icon: "Keyboard",
    subsections: [
      {
        title: messages.punctuation.basics.title,
        id: "basics",
        subtitle: messages.replacementPrompt,
        subsections: [
          punctuation.fullStop,
          punctuation.comma,
          punctuation.questionMark,
          punctuation.exclamationMark,
          punctuation.bulletPoint,
        ],
      },
      {
        title: messages.punctuation.advanced.title,
        id: "advanced",
        subtitle: messages.replacementPrompt,
        subsections: [
          punctuation.apostrophe,
          punctuation.asterisk,
          punctuation.atSign,
          punctuation.semicolon,
          punctuation.colon,
          punctuation.forwardSlash,
          punctuation.backSlash,
          punctuation.ampersand,
          punctuation.hash,
          punctuation.tilde,
          punctuation.caret,
          punctuation.underscore,
          punctuation.hyphen,
          punctuation.nDash,
          punctuation.mDash,
          punctuation.pipe,
          punctuation.openDoubleQuote,
          punctuation.closeDoubleQuote,
          punctuation.openSingleQuote,
          punctuation.closeSingleQuote,
        ],
      },
      {
        title: messages.punctuation.mathematics.title,
        id: "mathematics",
        subtitle: messages.replacementPrompt,
        subsections: [
          punctuation.plus,
          punctuation.minus,
          punctuation.multiplication,
          punctuation.division,
          punctuation.equals,
          punctuation.plusMinus,
          punctuation.degree,
          punctuation.percent,
          punctuation.point,
          punctuation.lessThan,
          punctuation.greaterThan,
          punctuation.exponent,
        ],
      },
      {
        title: messages.punctuation.currency.title,
        id: "currency",
        subtitle: messages.replacementPrompt,
        subsections: [punctuation.euro, punctuation.dollar, punctuation.pound],
      },
      {
        title: messages.punctuation.brackets.title,
        id: "brackets",
        subtitle: messages.replacementPrompt,
        subsections: [
          punctuation.openParenthesis,
          punctuation.closeParenthesis,
          punctuation.openBrace,
          punctuation.closeBrace,
          punctuation.openBracket,
          punctuation.closeBracket,
          punctuation.openAngleBracket,
          punctuation.closeAngleBracket,
        ],
      },
    ],
  },
  {
    id: "textEditing",
    title: messages.textEditing.title,
    icon: "PencilCircle",
    subsections: [
      {
        id: "formatting",
        title: messages.textEditing.formatting.title,
        subtitle: messages.commandPrompt,
        subsections: [
          commands.boldOn,
          commands.boldOff,
          commands.italicOn,
          commands.italicOff,
          commands.underlineOn,
          commands.underlineOff,
        ],
      },
      {
        id: "selecting",
        title: messages.textEditing.selecting.title,
        subtitle: messages.commandPrompt,
        subsections: [commands.selectPhrase, commands.selectRange],
      },
      {
        id: "deleting",
        title: messages.textEditing.deleting.title,
        subtitle: messages.commandPrompt,
        subsections: [
          commands.clear,
          commands.undo,
          commands.redo,
          commands.pressDelete,
          commands.deleteSelection,
          commands.deletePhrase,
          commands.deleteRange,
        ],
      },
    ],
  },
  {
    id: "textNavigation",
    title: messages.textNavigation.title,
    icon: "Compass",
    subsections: [
      {
        id: "lineBreaks",
        subtitle: messages.insertionPrompt,
        title: messages.textNavigation.lineBreaks.title,
        subsections: [newLine, newParagraph],
      },
      {
        id: "keyStrokes",
        title: messages.textNavigation.keyStrokes.title,
        subtitle: messages.commandPrompt,
        subsections: [
          commands.arrowUp,
          commands.arrowDown,
          commands.arrowLeft,
          commands.arrowRight,
          commands.pressEnter,
        ],
      },
    ],
  },
  ...(getAppContext() === "desktop"
    ? [
        {
          id: "system",
          title: messages.system.title,
          icon: "Laptop",
          subsections: [
            {
              id: "appCommands",
              subtitle: messages.commandPrompt,
              title: messages.system.appCommands.title,
              subsections: [commands.openApp],
            },
          ],
        },
      ]
    : []),
];

export const lowerCaseListingStructure: ListingStructure =
  uncapitaliseListingStructure(listingStructure);
