import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { manageDeletion } from "./manageDeletion";
import { manageInitialiseSelection } from "./manageInitialiseSelection";
import { manageSelectPhrase } from "./manageSelectPhrase";
import { manageSelectRange } from "./manageSelectRange";

export const setUpSelection = function* (): SagaIterator<void> {
  yield all(
    [
      manageDeletion,
      manageSelectPhrase,
      manageSelectRange,
      manageInitialiseSelection,
    ].map(retry)
  );
};
