import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { requestSendResultToDesktopApp } from "@talktype/results/src/sagas/actions";

import { upsertDictationHistoryEntry } from "../reducers/dictateToApp";

export const manageDictationHistory = function* (): SagaIterator<void> {
  yield takeEvery(
    requestSendResultToDesktopApp,
    function* ({ payload: { transcript, ...result } }) {
      const text = transcript.reduce(
        (acc, segment) => (segment.type === "text" ? acc + segment.text : acc),
        ""
      );

      if (text === "") {
        return;
      }

      yield put(upsertDictationHistoryEntry({ text, ...result }));
    }
  );
};
