import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpSystem } from "@talktype/system";
import { setUpToasts } from "@talktype/toasts/src/sagas/setUpToasts";

import { configureIdleDetection } from "./configureIdleDetection";
import { configurePkce } from "./configurePkce";
import { configureSentry } from "./configureSentry";
import { configureTracking } from "./configureTracking";
import { configureUpdater } from "./configureUpdater";
import { configureUser } from "./configureUser";
import { keepScreenOnDuringDictation } from "./keepScreenOnDuringDictation";
import { logEnvIfDev } from "./logEnvIfDev";
import { notifyInvalidDesktopInstallation } from "./notifyInvalidDesktopInstallation";
import { setUpColourScheme } from "./setUpColourScheme";
import { addEditorToWindowForCypress } from "./testing/addEditorToWindowForCypress";

export const setUpCore = function* (): SagaIterator<void> {
  yield retry(configureSentry);
  yield retry(setUpToasts);
  yield all(
    [
      notifyInvalidDesktopInstallation,
      addEditorToWindowForCypress,
      configurePkce,
      configureUser,
      keepScreenOnDuringDictation,
      setUpColourScheme,
      setUpSystem,
      configureTracking,
      configureIdleDetection,
      configureUpdater,
      logEnvIfDev,
    ].map(retry)
  );
};
