import type { CreateTrackerConfig } from "./createTracker";
import type {
  ActionCommandProperties,
  BaseCommandConfig,
  CommandConfig,
  InlineCommandProperties,
} from "../../../types";
import type { Override } from "@carescribe/types/src/Override";

import { createTracker } from "./createTracker";

export type CreateCommandDefinitionConfig = (
  | InlineCommandProperties
  | ActionCommandProperties
) &
  Override<
    BaseCommandConfig,
    {
      input: string[];
      track: CreateTrackerConfig;
    }
  >;

/**
 * Create Command Definition
 *
 * Creates a command definition based on the config passed in. Most properties
 * are just copied into a new object, but tracking has special treatment:
 *
 * - creates an analytics tracker using createTracker
 */
export const createCommandDefinition = ({
  track,
  ...config
}: CreateCommandDefinitionConfig): CommandConfig => ({
  ...config,
  track: createTracker({
    ...track,
    keywords: [...config.matchers, ...(track.keywords ?? [])],
  }),
});
