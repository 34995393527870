import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { move } from "../../../../sagas/commands/cursor/move";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const arrowDown: CommandConfig = createCommandDefinition({
  id: "arrowDown",
  matchers: messages.matchers,
  type: "action",
  deadline: toSeconds(0),
  messages,
  performCommand: { talktype: move("down"), app: null },
  input: messages.listing,
  track: { name: "Key Pressed", data: { key: "Arrow Down" } },
});
