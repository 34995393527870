import type { PromptWithCommand } from "../types";
import type { CommandStatus } from "../types/CommandStatus";
import type { IndexedCommandId, AppMetadata } from "@talktype/types";
import type { SagaIterator } from "redux-saga";

import { put } from "redux-saga/effects";

import { voiceCommandUnsupported, voiceCommandComplete } from "./actions";

export const notifyCommandOutcome = ({
  id,
  prompt,
  targetApp,
}: {
  id: IndexedCommandId;
  prompt: PromptWithCommand;
  targetApp: AppMetadata;
}) =>
  function* (status: CommandStatus): SagaIterator<void> {
    if (status === "unsupported") {
      yield put(voiceCommandUnsupported({ targetApp }));
      return;
    }

    yield put(
      voiceCommandComplete({
        id,
        prompt,
        outcome: { status, detail: prompt.command.messages[status] },
      })
    );
  };
