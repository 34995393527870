import type { AssignedResult, InitialResult } from "@talktype/types";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

const action = prefixActionCreator("saga/talktype-transcriber");

export const receivedResult = action<
  InitialResult | AssignedResult,
  "received-result"
>("received-result");

export const requestStartMonitoringConnection = action<
  void,
  "request-start-monitoring-connection"
>("request-start-monitoring-connection");

export const requestStopMonitoringConnection = action<
  void,
  "request-stop-monitoring-connection"
>("request-stop-monitoring-connection");

export const requestStartAutoReconnect = action<
  { url: string },
  "request-start-auto-reconnect"
>("request-start-auto-reconnect");

export const requestStopAutoReconnect = action<
  void,
  "request-stop-auto-reconnect"
>("request-stop-auto-reconnect");

export const requestNewResult = action<void, "request-new-result">(
  "request-new-result"
);

export const poorConnectionDetected = action<void, "poor-connection-detected">(
  "poor-connection-detected"
);

export const connectionRestored = action<void, "connection-restored">(
  "connection-restored"
);
