import type {
  FormattingBarButtonDefinition,
  FormattingBarButtonName,
} from "@talktype/types";

import {
  ArrowArcLeft,
  ArrowArcRight,
  CopySimple,
  FileX,
  TextB,
  TextItalic,
  TextUnderline,
} from "@phosphor-icons/react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";
import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";

export type ButtonSection = Partial<
  Record<FormattingBarButtonName, FormattingBarButtonDefinition>
>;

type Buttons = {
  formatting: ButtonSection;
  history: ButtonSection;
  misc: ButtonSection;
};

export const buttons: Buttons = {
  formatting: {
    bold: {
      buttonProps: {
        Icon: TextB,
        className: createSelectorClassName("bold", "interactive"),
      },
      tooltipProps: { messages: { visible: messages.bold } },
    },
    italic: {
      buttonProps: {
        Icon: TextItalic,
        className: createSelectorClassName("italic", "interactive"),
      },
      tooltipProps: { messages: { visible: messages.italic } },
    },
    underline: {
      buttonProps: {
        Icon: TextUnderline,
        className: createSelectorClassName("underline", "interactive"),
      },
      tooltipProps: { messages: { visible: messages.underline } },
    },
  },
  history: {
    undo: {
      buttonProps: {
        Icon: ArrowArcLeft,
        className: createSelectorClassName("undo", "interactive"),
      },
      tooltipProps: { messages: { visible: messages.undo } },
    },
    redo: {
      buttonProps: {
        Icon: ArrowArcRight,
        className: createSelectorClassName("redo", "interactive"),
      },
      tooltipProps: { messages: { visible: messages.redo } },
    },
  },
  misc: {
    copy: {
      buttonProps: {
        Icon: CopySimple,
        className: createSelectorClassName("copy", "interactive"),
      },
      tooltipProps: {
        messages: {
          visible: messages.copy,
          confirming: { content: messages.copied, duration: toSeconds(2) },
        },
        // Match width of longer message to prevent layout shift
        minWidth: 145,
      },
    },
    clear: {
      buttonProps: {
        Icon: FileX,
        className: createSelectorClassName("clear", "interactive"),
      },
      tooltipProps: { messages: { visible: messages.clear } },
    },
  },
};
