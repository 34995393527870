import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, takeEvery, take } from "redux-saga/effects";

import {
  requestCategoriseResult,
  categorisedResult,
  requestBroadcastInProgressResult,
  broadcastedInProgressResult,
} from "../actions";

/**
 * Categorise Results
 *
 * Take every result and determine how it should be processed.
 *
 * - isNew: Result is not the same as the in-progress result
 * - isStale: Final version of the result has previously been processed
 * - previousIsUnfinalised: Result received prior to previous in-progress
 *   result finalising
 */
export const categoriseResult = function* (): SagaIterator<void> {
  yield takeEvery(
    requestCategoriseResult,
    function* ({ payload: { resultId } }): SagaIterator<void> {
      yield put(requestBroadcastInProgressResult());

      const {
        payload: inProgress,
      }: SagaReturnType<typeof broadcastedInProgressResult> = yield take(
        broadcastedInProgressResult
      );

      const isNew = resultId !== inProgress.current.result.resultId;
      const isStale = inProgress.current.previousIds.has(resultId);
      const previousResult = inProgress.current.result;
      const previousIsUnfinalised =
        resultId !== previousResult.resultId && !previousResult.isFinal;

      yield put(
        categorisedResult({
          isNew,
          isStale,
          previousIsUnfinalised,
        })
      );
    }
  );
};
