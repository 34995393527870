type Keys = Record<string, string | null>;

export const scopeKeys = (keys: Record<string, string | null>): string => {
  const entries = Object.entries(keys)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .filter((entry): entry is [string, string] => entry[1] !== null);

  const params = new URLSearchParams();
  entries.forEach(([key, value]) => params.append(key, value));

  return params.toString();
};

export const Storage = {
  getItem: (keys: Keys): string | null => localStorage.getItem(scopeKeys(keys)),
  removeItem: (keys: Keys): void => localStorage.removeItem(scopeKeys(keys)),
  setItem: (keys: Keys, value: string): void =>
    localStorage.setItem(scopeKeys(keys), value),
};
