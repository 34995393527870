import type { InProgressResult } from "@talktype/types";
import type { SagaIterator } from "redux-saga";

import { takeEvery, put } from "redux-saga/effects";

import { setInProgress } from "@talktype/editor";

import {
  requestTrackResult,
  trackedResult,
  requestBroadcastInProgressResult,
  broadcastedInProgressResult,
} from "./actions";
import { emptyInitialResult } from "../utils";

/**
 * Maximum number of results to track, to prevent memory leaks if the app runs
 * indefinitely.
 */
const maxResultCount = 50;

/**
 * Track In Progress Result
 *
 * Responsible for lifecycle of the in-progress result and keeps track of the
 * result and whether it is active or not.
 */
export const trackInProgressResult = function* (): SagaIterator<void> {
  const ref: InProgressResult = {
    current: { result: emptyInitialResult, previousIds: new Set() },
  };

  yield takeEvery(requestTrackResult, function* ({ payload: result }) {
    ref.current.result = result;

    if (result.isFinal) {
      ref.current.previousIds.add(result.resultId);
    }

    // Ensure the set does not grow indefinitely
    if (ref.current.previousIds.size >= maxResultCount) {
      const oldestKey = ref.current.previousIds.keys().next().value;
      ref.current.previousIds.delete(oldestKey);
    }

    yield put(setInProgress(!result.isFinal));
    yield put(trackedResult(result));
  });

  yield takeEvery(requestBroadcastInProgressResult, function* () {
    yield put(broadcastedInProgressResult(ref));
  });
};
