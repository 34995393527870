import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, select, takeEvery } from "redux-saga/effects";

import { selectShareAnonymisedUsage } from "@talktype/preferences";

import { requestTrackEvent, trackedTalkTypeEvent } from "./actions";

/**
 * Manage Track Event
 *
 * Respond to requests to track a user action.
 *
 * Does nothing if the user has not opted in to sharing anonymised usage data.
 */
export const manageTrackEvent = function* (): SagaIterator<void> {
  yield takeEvery(requestTrackEvent, function* ({ payload }) {
    const shareAnonymisedUsage: SagaReturnType<
      typeof selectShareAnonymisedUsage
    > = yield select(selectShareAnonymisedUsage);

    if (!shareAnonymisedUsage) {
      return;
    }

    yield put(trackedTalkTypeEvent(payload));
  });
};
