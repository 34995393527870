import type { DictationToast as DictationToastType } from "@talktype/types";
import type { DictationToastProps } from "@talktype/ui/src/DictationToast";
import type { ReactElement } from "react";

import { Ear, Robot, Check, Warning, ChatCircle } from "@phosphor-icons/react";

import { DictationToast as DictationToastComponent } from "@talktype/ui/src/DictationToast";

import { messages } from "./messages";

const getProps = ({
  status,
  text,
}: DictationToastType): Pick<
  DictationToastProps,
  "colour" | "icon" | "title" | "description"
> => {
  switch (status) {
    case "listening":
      return {
        colour: "brand",
        icon: <Ear weight="bold" />,
        title: { text: messages.listening, visible: true },
        description: {
          text: [
            { content: messages.TalkType + " ", userGenerated: false },
            { content: text.toLowerCase(), userGenerated: true },
          ],
          visible: true,
        },
      };

    case "unrecognised":
      return {
        colour: "brand",
        icon: <Robot weight="bold" />,
        title: { text: messages.unrecognised, visible: false },
        description: {
          text: [{ content: messages.noCommandDetected, userGenerated: false }],
          visible: true,
        },
      };

    case "unsupported":
      return {
        colour: "destructive",
        icon: <Warning weight="bold" />,
        title: { text: messages.unsupported, visible: false },
        description: {
          text: [
            {
              content: messages.commandIsNotSupportedIn(text),
              userGenerated: false,
            },
          ],
          visible: true,
        },
      };

    case "successful":
      return {
        colour: "brand",
        icon: <Check weight="bold" />,
        title: { text: messages.completed, visible: false },
        description: {
          text: [{ content: text, userGenerated: false }],
          visible: true,
        },
      };

    case "failed":
      return {
        colour: "destructive",
        icon: <Warning weight="bold" />,
        title: { text: messages.completed, visible: false },
        description: {
          text: [{ content: text, userGenerated: false }],
          visible: true,
        },
      };

    case "dictating-to":
      return {
        colour: "brand",
        icon: <ChatCircle weight="bold" />,
        title: { text: messages.dictatingTo + " " + text, visible: true },
        description: { text: [], visible: false },
      };
  }
};

export const DictationToastInstance = (
  toast: DictationToastType
): ReactElement => (
  <DictationToastComponent
    hierarchy="secondary"
    onClose={null}
    {...getProps(toast)}
  />
);
