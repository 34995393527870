import type { UserState } from "../types/UserState";

import { P } from "ts-pattern";

import { createGuard } from "@carescribe/utilities/src/guards/createGuard";

import { validMe } from "./isMe";
import { loginStatuses } from "../types/LoginStatus";

export const isUserState = createGuard<UserState>({
  licenceKey: P.union(P.string, P.nullish),
  loginStatus: P.union(...loginStatuses),
  talkTypeDashboardUrl: P.string,
  me: P.union(validMe, P.nullish),
});
