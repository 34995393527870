import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, takeEvery, take } from "redux-saga/effects";

import { isActionOfType } from "@carescribe/utilities/src/sagas";

import { requestProcessResult, processedResult } from "@talktype/actions";

import {
  requestFinaliseInProgressResult,
  finalisedInProgressResult,
  requestBroadcastInProgressResult,
  broadcastedInProgressResult,
} from "./actions";

/**
 * Normally, an in-progress result will be finalised organically as a result
 * of the transcript lifecycle. However, there are times when this flow is
 * interrupted (poor connection, user stops dictation, etc). In these cases
 * we still want to ensure the final version of the in-progress result
 * is processed and sent through the results pipeline, this saga makes
 * that possible.
 */
export const manageFinaliseInProgressResult = function* (): SagaIterator<void> {
  yield takeEvery(
    requestFinaliseInProgressResult,
    function* (): SagaIterator<void> {
      yield put(requestBroadcastInProgressResult());

      const {
        payload: inProgress,
      }: SagaReturnType<typeof broadcastedInProgressResult> = yield take(
        broadcastedInProgressResult
      );

      yield put(
        requestProcessResult({ ...inProgress.current.result, isFinal: true })
      );

      const waitForProcessedResult = (action: unknown): boolean =>
        isActionOfType(action, processedResult) &&
        action.payload.resultId === inProgress.current.result.resultId;

      yield take(waitForProcessedResult);

      yield put(finalisedInProgressResult());
    }
  );
};
