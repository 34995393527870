import type { CustomShortcut } from "@talktype/types";

import { hasPropertyOfType } from "@carescribe/utilities/src/guards/hasPropertyOfType";
import { isObject } from "@carescribe/utilities/src/guards/isObject";

export const isValidCustomShortcut = (
  customShortcut: unknown
): customShortcut is CustomShortcut =>
  isObject(customShortcut) &&
  hasPropertyOfType(customShortcut, "id", "string") &&
  hasPropertyOfType(customShortcut, "input", "string") &&
  hasPropertyOfType(customShortcut, "output", "string");
