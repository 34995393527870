import { newLineCharacter } from "@carescribe/slate";
import { trimWhitespace } from "@carescribe/utilities/src/trimWhitespace";

import {
  symbolsWithCapitalAfter,
  symbolsWithNoSpaceBefore,
  symbolsWithSpaceAfter,
  symbolsWithNoSpaceAfter,
} from "../config/punctuation";

export const shouldBeNoSpaceBeforeText = (text: string): boolean =>
  symbolsWithNoSpaceBefore.some((symbol) =>
    trimWhitespace(text).startsWith(symbol)
  );

export const shouldBeNoSpaceAfterText = (text: string): boolean =>
  symbolsWithNoSpaceAfter.some((symbol) => text.trim().endsWith(symbol));

export const textEndsWithCapitalizer = (text: string): boolean =>
  symbolsWithCapitalAfter.some((symbol) =>
    trimWhitespace(text).endsWith(symbol)
  );

export const textStartsWithPunctuation = (text: string): boolean =>
  text.startsWith(newLineCharacter) ||
  symbolsWithSpaceAfter.some((symbol) => text[0] === symbol);

export const concatRegex = (
  regexes: (RegExp | string)[],
  flags?: string
): RegExp => {
  const source = regexes.reduce((source, current) => {
    const reString = current instanceof RegExp ? current.source : current;
    return source + reString;
  }, "");

  return new RegExp(source, flags);
};

export const group = (regexes: (RegExp | string)[]): RegExp =>
  concatRegex(["(", ...regexes, ")"]);

export const lookahead = (regexes: (RegExp | string)[]): RegExp =>
  concatRegex(["(?=", ...regexes, ")"]);

export const optional = (regexes: (RegExp | string)[]): RegExp =>
  concatRegex([...regexes, "?"]);

export const replaceText = ({
  text,
  regex,
  replacement,
  onReplace,
}: {
  text: string;
  regex: RegExp;
  replacement: string;
  onReplace: (trigger: string, replacement: string) => void;
}): string => {
  const regexWithBoundaries = concatRegex([/\b/, regex, /\b/], "ig");

  return text.replace(regexWithBoundaries, (trigger) => {
    onReplace(trigger, replacement);

    return replacement;
  });
};
