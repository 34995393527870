import type { PkceState } from "../types/PkceState";
import type { SagaIterator } from "redux-saga";

import { all, call, put } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { getPkceFromLocalStorage } from "./persistence/getPkceFromLocalStorage";
import { setUpStorePkceInLocalStorage } from "./persistence/setUpStorePkceInLocalStorage";
import { setUpAuthenticate } from "./setUpAuthenticate";
import { setUpGetCode } from "./setUpGetCode";
import { setUpPkceCodePair } from "./setUpPkceCodePair";
import { setUpRefreshAccessToken } from "./tokens/setUpRefreshAccessToken";
import { setUpRequestRevokeAccessToken } from "./tokens/setUpRequestRevokeAccessToken";
import { setUpRequestTokens } from "./tokens/setUpRequestTokens";
import { setPkce } from "../reducer";

export const setUpPkce = function* (
  initialState: Partial<PkceState>,
  appName: string
): SagaIterator<void> {
  yield put(setPkce(initialState));

  const configureGetPkceFromLocalStorage = function* (): SagaIterator<void> {
    yield call(getPkceFromLocalStorage, appName);
  };

  const configureStorePkceInLocalStorage = function* (): SagaIterator<void> {
    yield call(setUpStorePkceInLocalStorage, appName);
  };

  yield all(
    [
      configureGetPkceFromLocalStorage,
      configureStorePkceInLocalStorage,
      setUpAuthenticate,
      setUpGetCode,
      setUpPkceCodePair,
      setUpRefreshAccessToken,
      setUpRequestRevokeAccessToken,
      setUpRequestTokens,
    ].map(retry)
  );
};
