import type { Stage } from "../../../types";

import { strict as chrono } from "chrono-node";
import { format as formatDate } from "date-fns";

export const dates: Stage = ({ text, settings }) => {
  const dateFormat = settings.dateFormat;

  if (!dateFormat) {
    return text;
  }

  const dates = chrono.parse(text);

  return dates.reduce((text, date) => {
    const formattedDate = formatDate(date.date(), dateFormat);

    return text.replace(new RegExp(`(the )?${date.text}`), formattedDate);
  }, text);
};
