import type { Preferences } from "@talktype/types";

import { documentFontSize } from "@talktype/config/src/documentFontSize";

export const initialState: Preferences = {
  alwaysOnTop: "never",
  colourScheme: "normal",
  customShortcuts: [],
  customWords: [],
  dateFormat: "",
  dictationLanguage: "en-GB",
  document: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: documentFontSize.default,
  },
  filterProfanity: true,
  numberFormat: "automatic",
  shareAnonymisedUsage: true,
  silenceTimeout: {
    magnitude: 5,
    quantity: "time",
    unit: "minutes",
  },
  drawer: {
    isOpen: false,
    page: "voiceCommands",
  },
};
