import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take, takeEvery, call } from "redux-saga/effects";

import { preventDefault } from "@carescribe/utilities/src/sagas";

import {
  broadcastedInProgressResult,
  requestBroadcastInProgressResult,
} from "@talktype/results/src/sagas/actions";

import { editorClicked, editorKeyDown } from "../actions";

/**
 * Prevent interactions whilst there is in-progress results:
 *
 * - editor is clicked
 * - key is pressed
 */
export const preventInteractions = function* (): SagaIterator<void> {
  yield takeEvery(
    [editorClicked, editorKeyDown],
    function* ({ payload: event }): SagaIterator<void> {
      yield put(requestBroadcastInProgressResult());

      const {
        payload: inProgressResult,
      }: SagaReturnType<typeof broadcastedInProgressResult> = yield take(
        broadcastedInProgressResult
      );

      if (!inProgressResult.current.result.isFinal) {
        yield call(preventDefault, event);
      }
    }
  );
};
