import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { getDocumentFromLocalStorage } from "./getDocumentFromLocalStorage";
import { revalidateDocumentOnError } from "./revalidateDocumentOnError";
import { setDocumentInLocalStorage } from "./setDocumentInLocalStorage";
import { setDocumentInRedux } from "./setDocumentInRedux";

export const setUpPersistentDocument = function* (): SagaIterator<void> {
  yield all(
    [
      getDocumentFromLocalStorage,
      setDocumentInLocalStorage,
      setDocumentInRedux,
      revalidateDocumentOnError,
    ].map(retry)
  );
};
