import { britishise } from "./filters/britishise";
import { profanity } from "./filters/profanity";
import { trim } from "./filters/trim";
import { capitalsAtSentenceStart } from "./formatters/capitalsAtSentenceStart";
import { capitalsAtStart } from "./formatters/capitalsAtStart";
import { dates } from "./formatters/dates";
import { emails } from "./formatters/emails";
import { numbers } from "./formatters/numbers";
import { punctuation } from "./formatters/punctuation";
import { specialCases } from "./formatters/specialCases";
import { statefulGrammar } from "./formatters/statefulGrammar";

export const stages = [
  britishise,
  dates,
  numbers,
  profanity,
  emails,
  capitalsAtStart,
  trim,
  specialCases,
  punctuation,
  capitalsAtSentenceStart,
  statefulGrammar,
];
