import { createSelector } from "@reduxjs/toolkit";

import { selectCustomShortcuts } from "./selectCustomShortcuts";
import { selectDateFormat } from "./selectDateFormat";
import { selectDictationLanguage } from "./selectDictationLanguage";
import { selectFilterProfanity } from "./selectFilterProfanity";
import { selectNumberFormat } from "./selectNumberFormat";

/**
 * Selects the settings needed for transcript transformation from state.
 */
export const selectTransformationPreferences = createSelector(
  [
    selectCustomShortcuts,
    selectFilterProfanity,
    selectDictationLanguage,
    selectDateFormat,
    selectNumberFormat,
  ],
  (
    customShortcuts,
    filterProfanity,
    dictationLanguage,
    dateFormat,
    numberFormat
  ) => ({
    customShortcuts,
    filterProfanity,
    britishise: dictationLanguage === "en-GB",
    dateFormat,
    numberFormat,
  })
);
