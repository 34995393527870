import {
  createSagaAction,
  prefixActionCreator,
} from "@carescribe/utilities/src/saga";

const action = prefixActionCreator("saga/transcriber-connection/socket");

export const wsOpen = action<void, "state/open">("state/open");

export const wsClose = action<CloseEvent, "state/close">("state/close");

export const wsMessage = action<MessageEvent<string>, "message">("message");

export const wsError = action<Event, "state/error">("state/error");

export const requestNewTranscriberSocket = action<{ url: string }, "new">(
  "new"
);

export const transcriberSocketCreated = action<WebSocket, "created">("created");

export const requestCurrentTranscriberSocket = action<void, "current">(
  "current"
);

export const requestCloseCurrentWebSocket = action<void, "request-close">(
  "request-close"
);

/**
 * @TODO "response" isn't a great name for what this action does
 */
export const broadcastCurrentTranscriberSocket = action<
  WebSocket | null,
  "response"
>("response");

export const transcriberSocketConnected = action<WebSocket, "state/connected">(
  "state/connected"
);

export const transcriberSocketClosed = action<void, "state/closed">(
  "state/closed"
);

export const transcriberSocketClosedWithUnknownError = action<
  void,
  "state/closed/unknown-error"
>("state/closed/unknown-error");

export const transcriberSocketClosedEnableAutoReconnection = action<
  void,
  "state/closed/auto-reconnect"
>("state/closed/auto-reconnect");

export const transcriberSocketClosedDisallowReconnection = action<
  number,
  "state/closed/no-reconnect"
>("state/closed/no-reconnect");

export const transcriberSocketClosedEnablePromptedReconnection = action<
  number,
  "state/closed/prompted-reconnect"
>("state/closed/prompted-reconnect");

export const transcriberSocketInterrupted = action<void, "state/interrupted">(
  "state/interrupted"
);

export const transcriberSocketMaintained = action<void, "state/maintained">(
  "state/maintained"
);

export const pongReceived = createSagaAction<
  void,
  "live-transcript/socket/pong"
>("live-transcript/socket/pong");
