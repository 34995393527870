import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call } from "redux-saga/effects";

import { UserStorage } from "@talktype/utilities/src/UserStorage";

import { isDictationMode } from "../../guards/isDictationMode";
import { setDictationMode } from "../../reducers";

/**
 * Attempts to load the dictation mode from local storage.
 */
export const getDictationModeFromLocalStorage =
  function* (): SagaIterator<void> {
    const dictationMode: SagaReturnType<typeof UserStorage.getItem> =
      yield call(UserStorage.getItem, "dictation_mode");

    if (!isDictationMode(dictationMode)) {
      return;
    }

    yield put(setDictationMode(dictationMode));
  };
