import type { QuickNavItem } from "@talktype/types";

import {
  ArrowBendDoubleUpRight,
  BookBookmark,
  ClockCounterClockwise,
} from "@phosphor-icons/react";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { VoiceCommandsIcon } from "@talktype/icons/src/VoiceCommandsIcon";

import { messages } from "./messages";

const context = getAppContext();

const allItems: Omit<QuickNavItem, "onClick" | "active" | "highlight">[] = [
  {
    id: "voiceCommands",
    selectorId: "voice-commands",
    messages: messages.quickNav.voiceCommands,
    visibility: { browser: true, desktop: true },
    Icon: VoiceCommandsIcon,
  },
  {
    id: "customWords",
    selectorId: "custom-words",
    messages: messages.quickNav.customWords,
    visibility: { browser: true, desktop: true },
    Icon: BookBookmark,
  },
  {
    id: "shortcuts",
    selectorId: "shortcuts",
    messages: messages.quickNav.shortcuts,
    visibility: { browser: true, desktop: true },
    Icon: ArrowBendDoubleUpRight,
  },
  {
    id: "history",
    selectorId: "history",
    messages: messages.quickNav.history,
    visibility: { browser: false, desktop: true },
    Icon: ClockCounterClockwise,
  },
];

export const quickNavItems = allItems.filter(
  (item) => item.visibility[context]
);
