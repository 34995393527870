import type { RootState } from "@talktype/store";
import type { WelcomeProps } from "@talktype/ui/src/Welcome";

import { connect } from "react-redux";

import { selectAuthorizeUrl } from "@carescribe/pkce/src/reducer/selectors/selectAuthorizeUrl";
import { selectRegisterUrl } from "@carescribe/pkce/src/reducer/selectors/selectRegisterUrl";

import { Welcome as Component } from "@talktype/ui/src/Welcome";

import { HeaderBar } from "./HeaderBar";

const mapStateToProps = (
  state: RootState
): Pick<WelcomeProps, "header" | "loginLink" | "redemptionLink"> => ({
  header: <HeaderBar style="borderless" colour="brand" />,
  loginLink: selectAuthorizeUrl(state),
  redemptionLink: selectRegisterUrl(state),
});

export const Welcome = connect(mapStateToProps)(Component);
