import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take, takeEvery } from "redux-saga/effects";

import {
  requestFinaliseDictation,
  finalisedDictation,
} from "@talktype/actions";
import {
  requestFinaliseInProgressResult,
  finalisedInProgressResult,
  requestBroadcastInProgressResult,
  broadcastedInProgressResult,
} from "@talktype/results/src/sagas/actions";
import { requestNewResult } from "@talktype/transcriber/src/sagas/actions";

/**
 * Causes finalisation behaviours upon request.
 *
 * - Finalises in-progress result
 * - Requests a new result with the transcriber
 */
export const setUpFinalisation = function* (): SagaIterator<void> {
  yield takeEvery(requestFinaliseDictation, function* (): SagaIterator<void> {
    yield put(requestBroadcastInProgressResult());

    const {
      payload: inProgressResult,
    }: SagaReturnType<typeof broadcastedInProgressResult> = yield take(
      broadcastedInProgressResult
    );

    if (!inProgressResult.current.result.isFinal) {
      yield put(requestFinaliseInProgressResult());
      yield take(finalisedInProgressResult);

      yield put(requestNewResult());
    }

    yield put(finalisedDictation());
  });
};
