import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery, select } from "redux-saga/effects";

import {
  setPreferences,
  selectColourScheme,
  setColourScheme,
} from "@talktype/preferences";
import { replaceState } from "@talktype/store/src/actions";

const updateColourScheme = function* (): SagaIterator<void> {
  const colourScheme: SagaReturnType<typeof selectColourScheme> = yield select(
    selectColourScheme
  );

  document.body.className = colourScheme;
};

/**
 * Manages the application's colour scheme.
 *
 * It listens for colour scheme changes and applies the appropriate color scheme
 * to the app via the `color-scheme` attribute.
 */
export function* setUpColourScheme(): SagaIterator<void> {
  yield call(updateColourScheme);

  yield takeEvery(
    [setColourScheme, setPreferences, replaceState],
    updateColourScheme
  );
}
