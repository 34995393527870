import type { SagaIterator } from "redux-saga";

import { spawn } from "redux-saga/effects";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { setUpTracking } from "@talktype/analytics/src/sagas/setUpTracking";

import { getBuildId } from "../utils/getBuildId";

export const configureTracking = function* (): SagaIterator<void> {
  yield spawn(setUpTracking, {
    context: getAppContext(),
    buildId: getBuildId(),
  });
};
