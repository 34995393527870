import type { SagaIterator, Task } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import {
  all,
  cancel,
  put,
  select,
  take,
  takeLeading,
} from "redux-saga/effects";

import { setUpScreenWakeLock } from "@carescribe/screen-wake-lock";
import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpVoiceCommands } from "@talktype/commands";
import { setUpDictateAnywhere } from "@talktype/dictate-to-app/src/sagas";
import { setUpEditor } from "@talktype/editor/src/sagas/setUpEditor";
import { setUpHotkeys } from "@talktype/hotkeys";
import { trackVoiceCommandSearch } from "@talktype/listing/src/sagas/trackVoiceCommandSearch";
import { setUpPreferences } from "@talktype/preferences";
import { setUpResults } from "@talktype/results";
import { replaceState } from "@talktype/store/src/actions";
import { selectLoggedInState } from "@talktype/store/src/selectors/selectLoggedInState";
import { setUpConnection } from "@talktype/transcriber/src/sagas/setUpConnection";
import { setUpTranscriptTransformation } from "@talktype/transcript-transformation/src/sagas";
import { setLoginStatus } from "@talktype/user/src/reducer";
import {
  loginComplete,
  notLoggedIn,
  optimisticLogin,
} from "@talktype/user/src/sagas/actions";

import { connectOnDictationStart } from "./connectOnDictationStart";
import { manageDictationButton } from "./manageDictationButton";
import { manageDictationStatus } from "./manageDictationStatus";
import { setUpAudioSocket } from "./setUpAudioSocket";
import { setUpFinalisation } from "./setUpFinalisation";
import { trackActiveAppBasedOnDictationMode } from "./trackActiveAppBasedOnDictationMode";

const loggedInSagas = [
  connectOnDictationStart,
  manageDictationButton,
  manageDictationStatus,
  setUpAudioSocket,
  setUpConnection,
  setUpDictateAnywhere,
  setUpEditor,
  setUpFinalisation,
  setUpHotkeys,
  setUpPreferences,
  setUpResults,
  setUpScreenWakeLock,
  setUpTranscriptTransformation,
  setUpVoiceCommands,
  trackActiveAppBasedOnDictationMode,
  trackVoiceCommandSearch,
];

/**
 * Set Up User Lifecycle
 *
 * On login:
 * - boots user sagas
 * - informs the UI to show the editor
 *
 * On logout:
 * - resets global state
 * - cancels user sagas
 */
export const setUpUserLifecycle = function* (): SagaIterator<void> {
  const replacementState: SagaReturnType<typeof selectLoggedInState> =
    yield select(selectLoggedInState);

  // Use takeLeading in order to allow for optimistic login to start sagas
  // pre-emptively, and not end up with duplicate sagas running once login is
  // confirmed.
  yield takeLeading([optimisticLogin, loginComplete], function* () {
    // The following order is vital: logged in sagas must run before the editor
    // is shown because the editor component creates an editor instance that
    // must be captured by sagas.
    const tasks: Task[] = yield all(loggedInSagas.map(retry));
    yield put(setLoginStatus("loggedIn"));

    // Cleanup effects: reset anything relating to the user being logged in
    yield take(notLoggedIn);
    yield cancel(tasks);
    yield put(replaceState(replacementState));
  });
};
