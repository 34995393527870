import type { SagaIterator } from "redux-saga";

import { call } from "redux-saga/effects";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { setUpUpdater } from "@talktype/updater";

import {
  AUTO_UPDATE_DISABLED,
  AUTO_UPDATE_DESKTOP_URL,
  AUTO_UPDATE_POLL_FREQUENCY_SECONDS,
} from "../env";
import { logWarning } from "../log";
import { getBuildId } from "../utils/getBuildId";

const isDisabled = AUTO_UPDATE_DISABLED === "true";
const desktopUrl = AUTO_UPDATE_DESKTOP_URL;
const pollFrequency = toSeconds(parseInt(AUTO_UPDATE_POLL_FREQUENCY_SECONDS));
const buildId = getBuildId();

export const configureUpdater = function* (): SagaIterator<void> {
  if (isDisabled) {
    logWarning("Auto updates are disabled");
    return;
  }

  const config = {
    desktop: { url: desktopUrl },
    browser: { buildId },
    pollFrequency,
  };

  yield call(setUpUpdater, config);
};
