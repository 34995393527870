import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { getLegacyPreferences } from "./getLegacyPreferences";
import { getPreferencesFromLocalStorage } from "./getPreferencesFromLocalStorage";
import { reshapeLegacyPreferences } from "./reshapeLegacyPreferences/reshapeLegacyPreferences";
import { setPreferencesInLocalStorage } from "./setPreferencesInLocalStorage";
import { storePreferencesInRedux } from "./storePreferencesInRedux";

export const setUpPersistentPreferences = function* (): SagaIterator<void> {
  yield all(
    [
      getLegacyPreferences,
      getPreferencesFromLocalStorage,
      reshapeLegacyPreferences,
      setPreferencesInLocalStorage,
      storePreferencesInRedux,
    ].map(retry)
  );
};
