import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, select, takeEvery } from "redux-saga/effects";

import { Storage } from "@carescribe/utilities/src/Storage";

import { actions } from "../../reducer";
import { selectUser } from "../../reducer/selectors/selectUser";

/**
 * Set User In Local Storage
 *
 * Saves the user state into local storage each time it changes (determined by
 * any action being dispatched to the reducer)
 */
export const setUserInLocalStorage = function* (): SagaIterator<void> {
  yield takeEvery(Object.values(actions), function* (): SagaIterator<void> {
    const user: SagaReturnType<typeof selectUser> = yield select(selectUser);

    const userAsString: SagaReturnType<typeof JSON.stringify> = yield call(
      [JSON, "stringify"],
      user
    );

    yield call(Storage.setItem, { key: "user", app: "talktype" }, userAsString);
  });
};
