import type { PkceCodePair } from "../types/PkceCodePair";

import { P } from "ts-pattern";

import { createGuard } from "@carescribe/utilities/src/guards/createGuard";

export const validPkceCodePair = {
  codeChallenge: P.string,
  codeVerifier: P.string,
};

export const isPkceCodePair = createGuard<PkceCodePair>(validPkceCodePair);
