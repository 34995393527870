import type { AppContext } from "@carescribe/types";

import { isElectronEnvironment } from "./isElectronEnvironment";

/**
 * Determines the context the app is running in.
 *
 * - desktop
 * - browser
 *
 * @example
 * ```ts
 * const context = getAppContext(); // 'desktop'
 * ```
 */
export const getAppContext = (): AppContext => {
  if (isElectronEnvironment()) {
    return "desktop";
  }

  return "browser";
};
