import type { Void } from "@carescribe/types";

/**
 * Element Is Focusable
 *
 * Asserts that an instance of Element has a focus property. This facilitates
 * writing checks that don't rely on an element being an instance of specific
 * element types, which make testing sagas harder.
 */
export const elementIsFocusable = <E extends Element>(
  e: E | null
): e is E & { focus: Void } => e !== null && "focus" in e;
