import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, select, take, takeEvery } from "redux-saga/effects";

import { accessTokenRevoked, authenticationComplete } from "./actions";
import { setPkceCodePair, setPkce } from "../reducer";
import { selectPkceCodePair } from "../reducer/selectors/selectPkceCodePair";
import { generateCodeChallenge } from "../utils/generateCodeChallenge";
import { generateCodeVerifier } from "../utils/generateCodeVerifier";

/**
 * Generate PKCE Code Pair
 *
 * Generate and store a new code pair in state.
 */
export const generatePkceCodePair = function* (): SagaIterator<void> {
  const codeVerifier: SagaReturnType<typeof generateCodeVerifier> = yield call(
    generateCodeVerifier
  );

  const codeChallenge: SagaReturnType<typeof generateCodeChallenge> =
    yield call(generateCodeChallenge, codeVerifier);

  yield put(setPkceCodePair({ codeChallenge, codeVerifier }));
};

/**
 * Set Up PKCE Code Pair
 *
 * Set up the PKCE code pair, and clear it when the authentication is complete.
 */
export const setUpPkceCodePair = function* (): SagaIterator<void> {
  // Wait for state restoration before taking any action
  yield take(setPkce);

  const codePair: SagaReturnType<typeof selectPkceCodePair> = yield select(
    selectPkceCodePair
  );

  if (!codePair) {
    // There is no code pair, so we're not in the middle of an oauth handshake
    // and need to generate new ones.
    yield call(generatePkceCodePair);
  }

  yield takeEvery(authenticationComplete, function* ({ payload: successful }) {
    if (successful) {
      yield put(setPkceCodePair(null));
    }
  });

  yield takeEvery(accessTokenRevoked, function* () {
    yield call(generatePkceCodePair);
  });
};
