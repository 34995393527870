import type { Stage } from "../../../types";

import { britishConversionRules } from "../../../config/british-conversions";

export const britishise: Stage = ({ text, settings = {} }) => {
  if (!settings.britishise) {
    return text;
  }

  return britishConversionRules.reduce(
    (text, [british, american]) => text.replaceAll(american, british),
    text
  );
};
