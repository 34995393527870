import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { podBayDoors as performPodBayDoors } from "../../../../sagas/commands/easter-eggs/podBayDoors";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const podBayDoors: CommandConfig = createCommandDefinition({
  id: "podBayDoors",
  matchers: messages.matchers,
  type: "action",
  deadline: toSeconds(0),
  messages,
  performCommand: { talktype: performPodBayDoors, app: performPodBayDoors },
  input: [],
  track: {
    name: "Easter Egg Triggered",
    data: { easterEggType: "pod bay doors" },
  },
});
