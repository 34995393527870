import type { SagaIterator, Task } from "redux-saga";

import { take, put, takeEvery, cancel } from "redux-saga/effects";

import { startDictating, stopDictating } from "@talktype/editor";
import { setActiveApp } from "@talktype/system";
import { addToast, dismissToast } from "@talktype/toasts/src/sagas/actions";

const DICTATING_TO_TOAST_ID = "dictating-to";

/**
 * This saga listens for dictate-to-app related events and in response
 * dispatches the appropriate notifications.
 *
 * - "Dictating to" notification:
 *   - Displayed when dictating to other apps
 *   - Displays the name of the app
 */
export const manageNotifications = function* (): SagaIterator<void> {
  yield takeEvery(startDictating, function* () {
    const task: Task = yield takeEvery(
      setActiveApp,
      function* ({ payload: activeApp }) {
        switch (activeApp.isSelf) {
          case true:
            yield put(dismissToast("dictating-to"));
            break;
          case false: {
            yield put(
              addToast({
                id: DICTATING_TO_TOAST_ID,
                type: "dictation",
                status: "dictating-to",
                text: activeApp.name,
                order: 999, // Always last
                dismissConfig: { after: null, notBefore: null },
              })
            );
          }
        }
      }
    );

    yield take(stopDictating);
    yield cancel(task);
    yield put(dismissToast("dictating-to"));
  });
};
