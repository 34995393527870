import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { openApp as performOpenApp } from "../../../../sagas/commands/desktop/openApp";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const openApp: CommandConfig = createCommandDefinition({
  id: "openApp",
  matchers: messages.matchers,
  type: "action",
  deadline: toSeconds(3),
  messages,
  performCommand: { talktype: performOpenApp, app: performOpenApp },
  input: messages.listing,
  track: { name: "App Opened", data: null },
});
