import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call } from "redux-saga/effects";

import { isObject } from "@carescribe/utilities/src/guards/isObject";
import { StrictJSON } from "@carescribe/utilities/src/StrictJSON";

import { UserStorage } from "@talktype/utilities/src/UserStorage";

import { isChildren } from "../../guards/isChildren";
import { setDocument } from "../../reducers";
import { createDocument } from "../../utils/createDocument";
import { recoverCorruptedChildren } from "../../utils/recoverCorruptedChildren";

/**
 * Attempts to load the document from local storage.
 *
 * If it is found to be corrupted, attempts recovery.
 */
export const getDocumentFromLocalStorage = function* (): SagaIterator<void> {
  const storedDocument: SagaReturnType<typeof UserStorage.getItem> = yield call(
    UserStorage.getItem,
    "document"
  );

  if (!storedDocument) {
    return;
  }

  const [parsedDocument]: SagaReturnType<typeof StrictJSON.safeParse> =
    yield call(StrictJSON.safeParse, storedDocument);

  if (!isObject(parsedDocument) || !("children" in parsedDocument)) {
    return;
  }

  const { children } = parsedDocument;

  if (isChildren(children)) {
    yield put(setDocument(createDocument({ children })));
    return;
  }

  const recoveredChildren: SagaReturnType<typeof recoverCorruptedChildren> =
    yield call(recoverCorruptedChildren, children);

  if (recoveredChildren) {
    yield put(setDocument(createDocument({ children: recoveredChildren })));
  }
};
