type Success<T> = [T, null];
type Failure = [null, unknown];
type Outcome<T> = Success<T> | Failure;

/**
 * Regular JSON with added niceties:
 *
 * - `safeParse` method
 *   - Catches and returns any errors (e.g. invalid JSON format)
 *   - Strict return typing: `unknown` instead of `any`.
 */
export const StrictJSON = {
  /**
   * Converts a JavaScript Object Notation (JSON) string into an object.
   *
   * @param text - A valid JSON string.
   * @param reviver - A function that transforms the results.
   *                  This function is called for each member of the object.
   *                  If a member contains nested objects, the nested objects
   *                  are transformed before the parent object is.
   *
   * @returns An Outcome tuple, which includes:
   *           - The result of the operation, and
   *           - An error if the operation fails.
   *
   * @example
   *
   * ```
   * const invalidJSON = "{lorem:ipsum}";
   *
   * const parsed = JSON.parse(invalidJSON); // throws error
   * const [parsed, error] = StrictJSON.safeParse(invalidJSON); // this is fine!
   * ```
   */
  safeParse: (...params: Parameters<typeof JSON.parse>): Outcome<unknown> => {
    try {
      return [JSON.parse(...params), null];
    } catch (error) {
      return [null, error];
    }
  },
};
