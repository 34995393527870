import type { Descendant } from "slate";

/**
 * Stringifies the document contents, ensuring in-progress nodes are finalised.
 */
export const stringifyDocument = ({
  children,
}: {
  children: Descendant[];
}): string =>
  JSON.stringify({ children }).replaceAll(
    '"inProgress":true',
    '"inProgress":false'
  );
