import type {
  ReplacementInstruction,
  TranscriptTransformationSettings,
} from "@talktype/types";
import type { Listing } from "@talktype/types/src/Listing";

import { noOp } from "@carescribe/utilities/src/fp";

import { bulletPoint } from "@talktype/transcript-transformation/src/config/punctuation";

const bulletPointShortcut: ReplacementInstruction = {
  matchers: bulletPoint.matchers,
  type: "punctuation",
  segments: [{ type: "linebreak" }, { type: "text", text: "bullet point" }],
  inBuilt: true,
};

export const newLine: ReplacementInstruction & Listing = {
  id: "newLine",
  input: ["New line"],
  type: "custom-shortcut",
  segments: [{ type: "linebreak" }],
  inBuilt: true,
  matchers: ["new line"],
};

export const newParagraph: ReplacementInstruction & Listing = {
  id: "newParagraph",
  input: ["New paragraph"],
  type: "custom-shortcut",
  segments: [{ type: "paragraph" }],
  inBuilt: true,
  matchers: ["new paragraph"],
};

export const transformationSettings: TranscriptTransformationSettings = {
  britishise: false,
  dateFormat: "",
  filterProfanity: false,
  numberFormat: "automatic",
  customShortcuts: [newLine, newParagraph, bulletPointShortcut],
  onReplace: noOp,
};
