import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { createNewCustomShortcut } from "./createNewCustomShortcut";
import { addCustomShortcut } from "../../reducers/preferences";
import { broadcast, requestAddCustomShortcut } from "../actions";

/**
 * Handles requests to add a custom shortcut.
 */
export const manageAddCustomShortcut = function* (): SagaIterator<void> {
  yield takeEvery(
    requestAddCustomShortcut,
    function* ({ payload: shortcutProperties }) {
      const shortcut: SagaReturnType<typeof createNewCustomShortcut> =
        yield call(createNewCustomShortcut, shortcutProperties);

      yield put(broadcast(addCustomShortcut(shortcut)));
    }
  );
};
