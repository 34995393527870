import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, call } from "redux-saga/effects";

import { focusEditor } from "@carescribe/slate/src/utils/focusEditor";

import { editorLoaded, startDictating } from "../actions";
import { getEditor } from "../utils";

/**
 * Manages the editor's focus:
 *
 * - Focus when loaded
 * - Focus when dictation starts
 */
export const manageFocus = function* (): SagaIterator<void> {
  yield takeEvery(
    [editorLoaded, startDictating],
    function* (): SagaIterator<void> {
      const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

      if (!editor) {
        return;
      }

      yield call(focusEditor, editor);
    }
  );
};
