import type { DictationStatus } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, put, select } from "redux-saga/effects";

import {
  requestStopDictating,
  requestToggleDictation,
} from "@talktype/actions";
import { requestTrackEvent } from "@talktype/analytics";
import { startDictating } from "@talktype/editor";
import { selectDictationStatus } from "@talktype/editor/src/reducers/editor";

export const manageDictationButton = function* (): SagaIterator {
  yield takeEvery(
    requestToggleDictation,
    function* ({ payload: { interactionMethod } }) {
      const dictationStatus: SagaReturnType<typeof selectDictationStatus> =
        yield select(selectDictationStatus);

      switch (dictationStatus) {
        case "inactive":
          yield put(startDictating());
          break;
        case "loading":
        case "active":
          yield put(requestStopDictating());
      }

      const newDictationStatus: Record<DictationStatus, "on" | "off"> = {
        inactive: "on",
        loading: "off",
        active: "off",
      };

      yield put(
        requestTrackEvent({
          name: "Dictation Toggled",
          data: {
            newDictationStatus: newDictationStatus[dictationStatus],
            interactionMethod,
          },
        })
      );
    }
  );
};
