import type { Command } from "../../../../types";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import {
  requestPressEnter,
  pressedEnter,
} from "@talktype/editor/src/sagas/actions";

export const pressEnter: Command = function* () {
  yield put(requestPressEnter({ target: "app" }));

  const { payload: success }: SagaReturnType<typeof pressedEnter> = yield take(
    pressedEnter
  );

  return success;
};
