import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import * as reducers from "./reducers";

export const { reducer, actions } = createSlice({
  name: "system",
  initialState,
  reducers,
});

export const { setActiveApp, setWindowMaximised } = actions;

export * from "./selectors";
