import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { select } from "redux-saga/effects";

import { selectCustomWords } from "@talktype/preferences/src/reducers/preferences/selectors/selectCustomWords";
import { selectSilenceTimeout } from "@talktype/preferences/src/reducers/preferences/selectors/selectSilenceTimeout";

export const constructTranscriberUrl = function* (
  baseUrl: string
): SagaIterator<string> {
  const url = new URL(baseUrl);

  const words: SagaReturnType<typeof selectCustomWords> = yield select(
    selectCustomWords
  );

  const silenceTimeout: SagaReturnType<typeof selectSilenceTimeout> =
    yield select(selectSilenceTimeout);

  words.forEach(({ input }) =>
    url.searchParams.append("custom_terms[]", input)
  );

  url.searchParams.append(
    "silence_timeout_in_minutes",
    silenceTimeout === "" ? "5" : silenceTimeout.magnitude.toString()
  );

  return url.toString();
};
