import type { Preferences } from "@talktype/types";

import { isObject } from "@carescribe/utilities/src/guards/isObject";

import { isValidCustomShortcuts } from "./isValidCustomShortcuts";
import { isValidCustomWords } from "./isValidCustomWords";

export const isValidPreferences = (
  preferences: unknown
): preferences is Preferences =>
  isObject(preferences) &&
  isValidCustomWords(preferences?.customWords) &&
  isValidCustomShortcuts(preferences?.customShortcuts);
