import type { PickToastConfig } from "@carescribe/toasts/src/types";
import type {
  DictationToast,
  InvalidInstallationToast,
  PoorConnectionToast,
  UpdateAvailableToast,
  UpdateErrorToast,
  FailedConnectionToast,
} from "@talktype/types";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/talktype-toasts");

export const addToast = action<
  | PickToastConfig<DictationToast>
  | PickToastConfig<InvalidInstallationToast>
  | PickToastConfig<PoorConnectionToast>
  | PickToastConfig<UpdateAvailableToast>
  | PickToastConfig<UpdateErrorToast>
  | PickToastConfig<FailedConnectionToast>,
  "addToast"
>("addToast");

export const dismissToast = action<string, "dismissToast">("dismissToast");
