import { isUndefined } from "./isUndefined";

/**
 * Regular map with added niceties:
 *
 * - `getStrict` is like `get`, but it throws if the key is not found.
 */
export class StrictMap<K, V> extends Map<K, V> {
  public getStrict(key: K): V {
    const value = super.get(key);

    if (isUndefined(value)) {
      throw new Error(`Map key not found: ${key}`);
    }

    return value;
  }
}
