import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { deleteCustomWord } from "../../reducers/preferences";
import { broadcast, requestDeleteCustomWord } from "../actions";

/**
 * Handles requests to delete a custom word.
 */
export const manageDeleteCustomWord = function* (): SagaIterator<void> {
  yield takeEvery(requestDeleteCustomWord, function* ({ payload: id }) {
    yield put(broadcast(deleteCustomWord(id)));
  });
};
