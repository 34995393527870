import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { editCustomWord } from "../../reducers/preferences";
import { broadcast, requestEditCustomWord } from "../actions";

export const manageEditCustomWord = function* (): SagaIterator<void> {
  yield takeEvery(requestEditCustomWord, function* ({ payload: word }) {
    yield put(broadcast(editCustomWord(word)));
  });
};
