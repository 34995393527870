import type { SagaIterator } from "redux-saga";

import { takeEvery, put, take } from "redux-saga/effects";

import { stopDictating } from "@talktype/editor";
import {
  broadcastPrecedingText,
  appliedTransformations,
  requestDictateToAppPrecedingText,
} from "@talktype/results/src/sagas/actions";
import { getFinalCharacterOfResult } from "@talktype/utilities";

/**
 * Manage Dictate-to-App Preceding Text
 *
 * Keeps track of the final character of the previously completed result. The
 * Dictate to App feature in Electron has no way of reporting what the
 * character to the left of the user's cursor is, so we use this to make the
 * best guess of what is probably there based on the last result to be inserted
 */
export const manageDictateToAppPrecedingText =
  function* (): SagaIterator<void> {
    // Set up some state to keep track of the previous result's text
    // content for the purposes of performing stateful grammar in
    // dictate-to-app mode.
    const resultData = {
      previousText: "",
      currentText: "",
      id: "",
    };

    yield takeEvery(stopDictating, function* () {
      // Reset state used for previous text calculation (i.e. stateful grammar)
      resultData.previousText = "";
      resultData.currentText = "";
      resultData.id = "";
    });

    yield takeEvery(
      requestDictateToAppPrecedingText,
      function* ({ payload: result }) {
        yield put(broadcastPrecedingText(resultData.previousText));

        // Listen for the text to have been processed so that we can keep the
        // internal text tracker up to date
        const {
          payload: processedResult,
        }: ReturnType<typeof appliedTransformations> = yield take(
          appliedTransformations
        );

        const finalCharacter =
          getFinalCharacterOfResult(processedResult) ??
          // Some results may not form any text, so the default to the
          // previous text
          resultData.previousText;

        // Update the current text with every result, so it stays current
        resultData.currentText = finalCharacter;

        if (result.isFinal) {
          // Overwrite the previous text with the current one when final so that
          // the next result can use it to determine the right transformation
          resultData.id = result.resultId;
          resultData.previousText = resultData.currentText;
        }
      }
    );
  };
