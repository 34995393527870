import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { updateHistory } from "../../../../sagas/commands/history/updateHistory";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const redo: CommandConfig = createCommandDefinition({
  id: "redo",
  matchers: messages.matchers,
  type: "action",
  deadline: toSeconds(0),
  messages,
  performCommand: { talktype: updateHistory("redo"), app: null },
  input: messages.listing,
  track: { name: "Edits Reverted", data: { historyInteraction: "redo" } },
});
