import type { ReactElement, ReactNode } from "react";
import type { Saga } from "redux-saga";

import { Provider as ReduxProvider } from "react-redux";

import { setUpStore } from "../setUpStore";

export const Provider = ({
  rootSaga,
  children,
}: {
  rootSaga: Saga;
  children: ReactNode;
}): ReactElement => {
  const store = setUpStore(rootSaga);

  return <ReduxProvider store={store}>{children}</ReduxProvider>;
};
