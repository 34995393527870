import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpClear } from "./clear/setUpClear";
import { setUpCopy } from "./copy/setUpCopy";
import { setUpCursor } from "./cursor";
import { setUpDeletion } from "./deletion";
import { setUpFixes } from "./fixes";
import { setUpFocus } from "./focus";
import { setUpFormattingBar } from "./formattingBar";
import { setUpHistory } from "./history";
import { setUpInlineStyling } from "./inlineStyling";
import { setUpInsertLineBreak } from "./insertion/setUpInsertLineBreak";
import { setUpPressEnter } from "./insertion/setUpPressEnter";
import { setUpNormalizers } from "./normalizers";
import { setUpPersistentDictationMode } from "./persistentDictationMode";
import { setUpPersistentDocument } from "./persistentDocument";
import { setUpResults } from "./results";
import { setUpScroll } from "./scroll";
import { setUpSelection } from "./selection";
import { trackClipboardEvents } from "./trackClipboardEvents";
import { trackSplitEvents } from "./trackSplitEvents";
import { trackTextEvents } from "./trackTextEvents";
import { trackToggleDictationModeEvents } from "./trackToggleDictationModeEvents";
import { trackEditor } from "./utils/trackEditor";

export const setUpEditor = function* (): SagaIterator<void> {
  yield all(
    [
      trackEditor,
      setUpResults,
      setUpPersistentDocument,
      setUpPersistentDictationMode,
      setUpFixes,
      setUpFocus,
      setUpScroll,
      setUpNormalizers,
      setUpInlineStyling,
      setUpFormattingBar,
      setUpHistory,
      setUpSelection,
      setUpClear,
      setUpCopy,
      setUpDeletion,
      setUpCursor,
      trackTextEvents,
      trackSplitEvents,
      trackClipboardEvents,
      trackToggleDictationModeEvents,
      setUpInsertLineBreak,
      setUpPressEnter,
    ].map(retry)
  );
};
