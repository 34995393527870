import type {
  DetailedHTMLProps,
  DialogHTMLAttributes,
  ReactElement,
  RefObject,
} from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./modal.module.scss";

export type ModalProps = Omit<
  DetailedHTMLProps<DialogHTMLAttributes<HTMLDialogElement>, HTMLDialogElement>,
  "open"
> & {
  // TODO: Refactor to just `ref` after upgrading to React 19 (see {@link https://react.dev/blog/2024/04/25/react-19#ref-as-a-prop})
  modalRef: RefObject<HTMLDialogElement>;
  // TODO: Remove after upgrading eslint past v7.31.9 (see {@link https://arc.net/l/quote/aomdvnaz})
  onClose?: () => void;
};

export const Modal = ({
  modalRef,
  className,
  children,
  ...modalProps
}: ModalProps): ReactElement => (
  <dialog
    {...modalProps}
    ref={modalRef}
    className={classNames(styles.modal, className)}
  >
    <div className={styles.contents}>{children}</div>
  </dialog>
);
