import type { CommandConfig } from "../../../../types";

import { messages } from "./messages";
import { setInlineStyle } from "../../../../sagas/commands/formatting/setInlineStyle";
import { notifyCommandOutcome } from "../../../../sagas/notifyCommandOutcome";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const underlineOff: CommandConfig = createCommandDefinition({
  id: "underlineOff",
  matchers: messages.matchers,
  type: "inline",
  messages,
  input: messages.listing,
  createSegments: (info) => [
    {
      type: "command",
      performCommand: {
        talktype: setInlineStyle(["underline", false]),
        app: null,
      },
      onInsert: notifyCommandOutcome(info),
    },
  ],
  track: {
    name: "Style Toggled",
    data: { formattingStyleApplied: "underline", newStyleStatus: "off" },
  },
});
