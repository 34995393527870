import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { deletePhrase as performDeletePhrase } from "../../../../sagas/commands/deletion";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const deletePhrase: CommandConfig = createCommandDefinition({
  id: "deletePhrase",
  matchers: messages.matchers,
  type: "action",
  deadline: toSeconds(5),
  messages,
  performCommand: { talktype: performDeletePhrase, app: null },
  input: messages.listing,
  track: { name: "Text Deleted", data: { deletionType: "phrase" } },
});
