import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, call, put, take } from "redux-saga/effects";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";
import { assertUnreachable } from "@carescribe/utilities/src/types";

import { gotCode, gotCodeFromDeepLink, requestGetCode } from "./actions";

/**
 * Set Up Get Code
 *
 * Depending on the context, get the code from the URL or deep link.
 */
export const setUpGetCode = function* (): SagaIterator<void> {
  yield takeEvery(requestGetCode, function* () {
    const context: SagaReturnType<typeof getAppContext> = yield call(
      getAppContext
    );

    switch (context) {
      case "browser": {
        const url = new URL(window.location.href);
        const code = url.searchParams.get("code");

        if (code) {
          // Pretty up the address bar
          url.searchParams.delete("code");
          yield call(
            [history, "replaceState"],
            {},
            document.title,
            url.toString()
          );
        }

        yield put(gotCode(code));
        break;
      }

      case "desktop": {
        const { payload: code }: SagaReturnType<typeof gotCodeFromDeepLink> =
          yield take(gotCodeFromDeepLink);

        yield put(gotCode(code));
        break;
      }

      default:
        assertUnreachable(context);
    }
  });
};
