import type { ButtonSection } from "./config";
import type { Callback } from "@carescribe/types";
import type {
  FormattingBarButtonName,
  InteractionEvent,
} from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";
import { entries } from "@carescribe/utilities/src/typedObject";

import { buttons } from "./config";
import styles from "./formattingToolbar.module.scss";
import { ToggleableButton } from "../ToggleableButton";

export type FormattingButtonProps = {
  handleInteraction: (
    id: FormattingBarButtonName,
    event: InteractionEvent<HTMLButtonElement>
  ) => void;
  isActive: Callback<FormattingBarButtonName, boolean>;
  isDisabled: Callback<FormattingBarButtonName, boolean>;
};

export type FormattingToolbarProps = {
  fontSettings: ReactNode;
  buttonProps: FormattingButtonProps;
};

const ButtonGroup = ({
  section,
  isActive,
  isDisabled,
  handleInteraction,
}: FormattingButtonProps & { section: ButtonSection }): ReactElement => (
  <div className={styles.buttonGroup}>
    {entries(section).map(([id, { buttonProps, tooltipProps }]) => {
      const onInteraction = (
        event: InteractionEvent<HTMLButtonElement>
      ): void => handleInteraction(id, event);

      const active = isActive(id);
      const platform = getPlatform();
      return (
        <ToggleableButton
          key={id}
          buttonProps={{
            ...buttonProps,
            active,
            disabled: isDisabled(id),
            highlight: false,
            onMouseDown: onInteraction,
            onKeyDown: onInteraction,
          }}
          tooltipProps={{
            ...tooltipProps,
            messages: {
              ...tooltipProps.messages,
              visible: tooltipProps.messages.visible({ active, platform }),
            },
            placement: "bottom",
          }}
        />
      );
    })}
  </div>
);

const Spacer = (): ReactElement => (
  <div className={styles.spacer} aria-hidden />
);

export const FormattingToolbar = ({
  fontSettings,
  buttonProps,
}: FormattingToolbarProps): ReactElement => (
  <section
    className={classNames(
      styles.container,
      createSelectorClassName("formatting-toolbar", "section")
    )}
  >
    <div className={styles.buttonGroup}>{fontSettings}</div>
    <Spacer />
    <ButtonGroup {...buttonProps} section={buttons.formatting} />
    <Spacer />
    <ButtonGroup {...buttonProps} section={buttons.history} />
    <Spacer />
    <ButtonGroup {...buttonProps} section={buttons.misc} />
  </section>
);
