import type { ChangeEventHandler, ReactElement } from "react";

import { MagnifyingGlass } from "@phosphor-icons/react";
import { useRef, useState } from "react";

import { noOp } from "@carescribe/utilities/src/fp";

import { messages } from "./messages";
import { Field } from "../../Field";
import { ScreenReaderOnly } from "../../ScreenReaderOnly";
import { StandardButton } from "../../StandardButton";
import { TextInput } from "../../TextInput";

export type VoiceCommandsSearchProps = {
  onSearch: (searchTerm: string) => void;
  resultCount: number;
  isSearching: boolean;
};

export const VoiceCommandsSearch = ({
  onSearch,
  resultCount,
  isSearching,
}: VoiceCommandsSearchProps): ReactElement => {
  const [value, setValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setValue(value);
    onSearch(value);
  };

  const handleReset = (): void => {
    setValue("");
    onSearch("");
    inputRef.current?.focus();
  };

  const resultCountInfo = `${messages.found} ${resultCount} ${
    resultCount === 1 ? messages.result : messages.results
  }`;

  return (
    <form onReset={handleReset} onSubmit={noOp}>
      <Field
        label={{ text: messages.label, visible: false }}
        explainer={null}
        error={null}
      >
        <ScreenReaderOnly tag="span" aria-live="polite">
          {isSearching
            ? resultCountInfo
            : messages.resultPresentationInformation}
        </ScreenReaderOnly>

        <TextInput
          icon={<MagnifyingGlass />}
          size="xs"
          button={
            value.length === 0 ? null : (
              <StandardButton
                colour="neutral"
                hierarchy="link"
                size="xs"
                label={messages.clear}
                style="default"
                elementProps={{ type: "reset" }}
              />
            )
          }
          inputProps={{
            ref: inputRef,
            onChange: handleChange,
            value,
            type: "search",
            placeholder: messages.placeholder,
            maxLength: 100,
            "data-hj-allow": true,
          }}
        />
      </Field>
    </form>
  );
};
