import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, select, takeEvery } from "redux-saga/effects";

import { isChildren } from "../../guards/isChildren";
import { selectDocument, setDocument } from "../../reducers";
import { createDocument } from "../../utils/createDocument";
import { emptyChildren } from "../../utils/entities/emptyChildren";
import { recoverCorruptedChildren } from "../../utils/recoverCorruptedChildren";
import { editorErrored } from "../actions";

/**
 * Whenever an error in the editor is detected, this saga
 * revalidates the current document:
 *  - attempts recovery of text contents if it is corrupted
 *  - reverts to an empty text otherwise
 *  - history and selection are always reset
 */
export const revalidateDocumentOnError = function* (): SagaIterator<void> {
  yield takeEvery(editorErrored, function* (): SagaIterator<void> {
    const document: SagaReturnType<typeof selectDocument> = yield select(
      selectDocument
    );

    const { children } = document;

    const isValidChildren: SagaReturnType<typeof isChildren> = yield call(
      isChildren,
      document.children
    );

    if (isValidChildren) {
      /**
       * While children found to be valid, the source of the error may relate
       * to the history or selection.
       *
       * And so, we reset the document state (including history, selection),
       * while retaining only the children.
       */
      yield put(setDocument(createDocument({ children })));

      return;
    }

    const recoveredChildren: SagaReturnType<typeof recoverCorruptedChildren> =
      yield call(recoverCorruptedChildren, children);

    yield put(
      setDocument(
        createDocument({ children: recoveredChildren ?? emptyChildren })
      )
    );
  });
};
