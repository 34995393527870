import type { AppDispatch, RootState } from "@talktype/store";
import type { DateFormat } from "@talktype/types/src/DateFormat";
import type { AutoDateFormattingFieldProps } from "@talktype/ui/src/PreferencesModal/AutoDateFormattingField";
import type { ChangeEventHandler } from "react";

import { connect } from "react-redux";

import { dateFormats } from "@talktype/config/src/dateFormats";
import {
  broadcast,
  isValidDateFormatValue,
  selectDateFormat,
  setDateFormat,
} from "@talktype/preferences";
import { AutoDateFormattingField as Component } from "@talktype/ui/src/PreferencesModal/AutoDateFormattingField";

type StateProps = { value: DateFormat["value"] };

type DispatchProps = { onChange: ChangeEventHandler<HTMLSelectElement> };

const mapStateToProps = (state: RootState): StateProps => ({
  value: selectDateFormat(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onChange: ({ currentTarget: { value } }): void => {
    if (isValidDateFormatValue(value)) {
      dispatch(broadcast(setDateFormat(value)));
    }
  },
});

const mergeProps = (
  { value }: StateProps,
  { onChange }: DispatchProps
): AutoDateFormattingFieldProps => ({
  value,
  onChange,
  options: dateFormats,
});

export const AutoDateFormattingField = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
