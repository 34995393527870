import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take, takeEvery } from "redux-saga/effects";

import {
  requestHandlePrompt,
  handledPrompt,
  requestTrackPrompt,
  trackedPrompt,
  requestCancelTask,
  cancelledTask,
  requestStartTask,
  startedTask,
  voiceCommandListening,
  voiceCommandUnrecognised,
} from "./actions";
import { taskRef } from "./task/trackTask";
import { isActionCommand } from "../guards/isActionCommand";
import { deadlinesDiffer } from "../utils";

/**
 * This saga listens for any new voice command prompts
 * being detected and responds accordingly.
 */
export const managePrompt = function* (): SagaIterator<void> {
  const { payload: task }: SagaReturnType<typeof taskRef> = yield take(taskRef);

  yield takeEvery(
    requestHandlePrompt,
    function* ({ payload: { prompt, isFinal } }) {
      const finalUnrecognisedCommand = isFinal && !prompt.command;

      if (finalUnrecognisedCommand) {
        yield put(voiceCommandUnrecognised());
        yield put(handledPrompt());
        return;
      }

      const { command, instruction } = prompt;

      yield put(voiceCommandListening({ instruction }));
      yield put(requestTrackPrompt(prompt));
      yield take(trackedPrompt);

      if (
        task.current.task === null ||
        (isActionCommand(command) && deadlinesDiffer(command, task.current))
      ) {
        yield put(requestCancelTask());
        yield take(cancelledTask);

        yield put(requestStartTask());
        yield take(startedTask);
      }

      yield put(handledPrompt());
    }
  );
};
