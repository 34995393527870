import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { getDictationModeFromLocalStorage } from "./getDictationModeFromLocalStorage";
import { setDictationModeInLocalStorage } from "./setDictationModeInLocalStorage";

export const setUpPersistentDictationMode = function* (): SagaIterator<void> {
  yield all(
    [getDictationModeFromLocalStorage, setDictationModeInLocalStorage].map(
      retry
    )
  );
};
