import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call, takeEvery } from "redux-saga/effects";

import { historyUpdated, requestHistory } from "../actions";
import { getEditor } from "../utils";

/**
 * Manage History
 *
 * Takes any requests to undo/redo and calls the editor to do so.
 */
export const manageHistory = function* (): SagaIterator<void> {
  yield takeEvery(requestHistory, function* ({ payload }) {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

    if (!editor) {
      yield put(historyUpdated(false));
      return;
    }

    const historyStack =
      payload === "undo" ? editor.history.undos : editor.history.redos;

    const action = payload === "undo" ? editor.undo : editor.redo;

    if (historyStack.length) {
      yield call(action);
      yield put(historyUpdated(true));
    } else {
      yield put(historyUpdated(false));
    }
  });
};
