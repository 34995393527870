import type { RootState } from "../types";

import { safeStructuredClone } from "@carescribe/utilities/src/safeStructuredClone";

export const selectLoggedInState = (
  state: RootState
): Omit<RootState, "user" | "pkce"> => {
  const { user, pkce, ...result } = safeStructuredClone(state);
  return result;
};
