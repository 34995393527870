import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import { setPreferences } from "../../reducers";
import {
  gotLegacyPreferences,
  gotPreferencesFromLocalStorage,
  requestLegacyPreferences,
  requestPreferencesFromLocalStorage,
  requestReshapeLegacyPreferences,
  reshapedLegacyPreferences,
} from "../actions";

/**
 * Store Preferences in Redux
 *
 * Orchestrates storing preferences in Redux. Locates preferences in
 * localStorage, and failing that requests legacy preferences over IPC.
 */
export const storePreferencesInRedux = function* (): SagaIterator<void> {
  // Step 1.a: attempt to get preferences from localStorage
  yield put(requestPreferencesFromLocalStorage());

  const {
    payload: localStoragePreferences,
  }: SagaReturnType<typeof gotPreferencesFromLocalStorage> = yield take(
    gotPreferencesFromLocalStorage
  );

  if (localStoragePreferences) {
    // Step 1.b: store found preferences in Redux and exit the saga
    yield put(setPreferences(localStoragePreferences));
    return;
  }

  // Step 2.a: attempt to get legacy preferences over IPC
  yield put(requestLegacyPreferences());

  const {
    payload: legacyPreferences,
  }: SagaReturnType<typeof gotLegacyPreferences> = yield take(
    gotLegacyPreferences
  );

  if (legacyPreferences === null) {
    // Step 2.b: no legacy preferences were found, exit the saga
    return;
  }

  // Step 2.c: legacy preferences were found, send them for reshaping
  yield put(requestReshapeLegacyPreferences(legacyPreferences));

  const {
    payload: reshaped,
  }: SagaReturnType<typeof reshapedLegacyPreferences> = yield take(
    reshapedLegacyPreferences
  );

  if (reshaped) {
    // Step 2.d: store reshaped legacy preferences in Redux
    yield put(setPreferences(reshaped));
  }
};
