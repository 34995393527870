import type { AppDispatch, RootState } from "@talktype/store";
import type {
  FormattingButtonProps,
  FormattingToolbarProps,
} from "@talktype/ui/src/FormattingToolbar";

import { connect } from "react-redux";

import {
  editorFormattingBarInteracted,
  selectDocumentIsEmpty,
  selectDocumentIsRedoable,
  selectDocumentIsUndoable,
} from "@talktype/editor";
import { FormattingToolbar as Component } from "@talktype/ui/src/FormattingToolbar";
import { isInlineStyle } from "@talktype/utilities";

import { FontSettings } from "./FontSettings";

type StateProps = Pick<FormattingToolbarProps, "fontSettings"> & {
  buttonProps: Pick<FormattingButtonProps, "isActive" | "isDisabled">;
};

const mapStateToProps = ({ editor }: RootState): StateProps => ({
  buttonProps: {
    isActive: (id) => isInlineStyle(id) && editor.styles[id],
    isDisabled: (id) =>
      (id === "undo" && !selectDocumentIsUndoable({ editor })) ||
      (id === "redo" && !selectDocumentIsRedoable({ editor })) ||
      (id === "clear" && selectDocumentIsEmpty({ editor })) ||
      (id === "copy" && selectDocumentIsEmpty({ editor })),
  },
  fontSettings: <FontSettings />,
});

type DispatchProps = {
  buttonProps: Pick<FormattingButtonProps, "handleInteraction">;
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  buttonProps: {
    handleInteraction: (id, event) =>
      dispatch(editorFormattingBarInteracted({ name: id, event })),
  },
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps
): Pick<FormattingToolbarProps, "buttonProps" | "fontSettings"> => ({
  fontSettings: stateProps.fontSettings,
  buttonProps: {
    ...stateProps.buttonProps,
    ...dispatchProps.buttonProps,
  },
});

export const FormattingToolbar = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
