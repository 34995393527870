import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import {
  requestAddResultToEditor,
  requestSendResult,
  requestSendResultToDesktopApp,
} from "./actions";

export const sendResult = function* (): SagaIterator<void> {
  yield takeEvery(
    requestSendResult,
    function* ({ payload: result }): SagaIterator<void> {
      switch (result.targetApp.isSelf) {
        case false:
          yield put(requestSendResultToDesktopApp(result));
          break;
        case true:
          yield put(requestAddResultToEditor(result));
          break;
      }
    }
  );
};
