import type { CustomWord } from "@talktype/types";

import { hasPropertyOfType } from "@carescribe/utilities/src/guards/hasPropertyOfType";
import { isObject } from "@carescribe/utilities/src/guards/isObject";

export const isValidCustomWord = (
  customWord: unknown
): customWord is CustomWord =>
  isObject(customWord) &&
  hasPropertyOfType(customWord, "id", "string") &&
  hasPropertyOfType(customWord, "input", "string");
